import { ActionTypes, ActionTypesCollection } from "store/actionTypes";
import { IErrorStoreState, initialErrorState } from "store/state/errorStoreState";

function errorReducer(state: IErrorStoreState = initialErrorState, action: ActionTypesCollection): IErrorStoreState {
  switch (action.type) {
    case ActionTypes.UPDATED_DATA_ERROR:
      return {
        ...state,
        error: action.payload?.error,
        errorCode: action.payload?.errorCode,
        errorBody: action.payload?.errorBody,
      };
    default:
      return state;
  }
}

export default errorReducer;
