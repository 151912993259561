// tslint:disable:quotemark
export const TranslationMapper = {
  global: {
    back: "global.back",
    cancel: "global.cancel",
    cleaning: "global.cleaning",
    edit: "global.edit",
    house_number: "global.house_number",
    house_number_addition: "global.house_number_addition",
    ironing: "global.ironing",
    loading: "global.loading",
    next: "global.next",
    no: "global.no",
    yes: "global.yes",
    zipcode: "global.zipcode",

    error: {
      hero: {
        label: "global.error.hero.label",

        title: {
          default: "global.error.hero.title.default",
          fallback: "global.error.hero.title.fallback",
        },
      },
    },

    errors: {
      captcha: "global.errors.captcha",
      general: "global.errors.general",
      general_header: "global.errors.general_header",
      info: "global.errors.info",
      share: "global.errors.share",
      subscribe_to_waiting_list: "global.errors.subscribe_to_waiting_list",
      validate_discount: "global.errors.validate_discount",
      ampersand_error: "global.errors.ampersand_error",

      registration: {
        account_registration_completed: {
          message: "global.errors.registration.account_registration_completed.message",
          body: "global.errors.registration.account_registration_completed.body",
        },

        account_registration_expired: {
          message: "global.errors.registration.account_registration_expired.message",
          body: "global.errors.registration.account_registration_expired.body",
        },

        account_registration_fetch_offer: {
          message: "global.errors.registration.account_registration_fetch_offer.message",
          body: "global.errors.registration.account_registration_fetch_offer.body",
        },

        account_registration_general: {
          message: "global.errors.registration.account_registration_general.message",
        },

        account_registration_invalid_url: {
          message: "global.errors.registration.account_registration_invalid_url.message",
          body: "global.errors.registration.account_registration_invalid_url.body",
        },
      },
    },
  },

  account: {
    error: {
      header: "account.error.header",

      button_1: {
        label: "account.error.button_1.label",
      },

      button_2: {
        label: "account.error.button_2.label",
      },

      button_3: {
        label: "account.error.button_3.label",
      },

      mailto: {
        body: "account.error.mailto.body",
        subject: "account.error.mailto.subject",
      },
    },

    loader: {
      header: "account.loader.header",

      hero: {
        label: "account.loader.hero.label",
        title: "account.loader.hero.title",
      },
    },

    success: {
      header: "account.success.header",

      hero: {
        title: "account.success.hero.title",
      },

      download: {
        header: "account.success.download.header",
        text: "account.success.download.text",
      },
    },
  },

  advice: {
    title: "advice.title",
    text: "advice.text",

    button: {
      label: "advice.button.label",
    },

    price: {
      label: "advice.price.label",
      text: "advice.price.text",
    },

    room_type: {
      bathroom: {
        label: "advice.room_type.bathroom.label",
      },
      bedroom: {
        label: "advice.room_type.bedroom.label",
      },
      entrance: {
        label: "advice.room_type.entrance.label",
      },
      kitchen: {
        label: "advice.room_type.kitchen.label",
      },
      landing: {
        label: "advice.room_type.landing.label",
      },
      living_room: {
        label: "advice.room_type.living_room.label",
      },
      other_rooms: {
        label: "advice.room_type.other_rooms.label",
      },
      toilet: {
        label: "advice.room_type.toilet.label",
      },
    },
  },

  aside: {
    info: {
      header: "aside.info.header",
      label: "aside.info.label",
      text: "aside.info.text",
    },

    share: {
      header: "aside.share.header",
      label: "aside.share.label",
      text: "aside.share.text",
    },
  },

  discount: {
    title: "discount.title",
    text: {
      text1: "discount.text.text1",
      text2: "discount.text.text2",
      link_text: "discount.text.link_text",
    },

    button: {
      label: "discount.button.label",
    },

    form: {
      label: "discount.form.label",

      button: {
        label: "discount.form.button.label",
      },
    },
  },

  calculation: {
    employee: {
      title: "calculation.employee.title",

      every: {
        short: "calculation.employee.every.short",
        long: "calculation.employee.every.long",
      },
    },

    employer: {
      title: "calculation.employer.title",

      every: {
        short: "calculation.employer.every.short",
        long: "calculation.employer.every.long",
      },

      info: {
        percentage: "calculation.employer.info.percentage",
        max: "calculation.employer.info.max",
      },
    },
  },

  calculator: {
    amount: {
      frequency: "calculator.amount.frequency",
      header: "calculator.amount.header",
      text: "calculator.amount.text",
    },
    calculator: {
      header: "calculator.calculator.header",
      text: "calculator.calculator.text",
    },
    iron: {
      header: "calculator.iron.header",
      text: "calculator.iron.text",
      text2: "calculator.iron.text2",
    },
    next: "calculator.next",
    save_calculation: "calculator.save_calculation",
    text: {
      text1: "calculator.text.text1",
      text2: "calculator.text.text2",
      link_text: "calculator.text.link_text",
    },
    title: "calculator.title",
    usp: {
      label: "calculator.usp.label",
      text1: "calculator.usp.text1",
      text2: "calculator.usp.text2",
      text3: "calculator.usp.text3",
      text4: "calculator.usp.text4",
    },
    zipcode: {
      header: "calculator.zipcode.header",
      check: "calculator.zipcode.check",
      warning: "calculator.zipcode.warning",
      valid: {
        header: "calculator.zipcode.valid.header",
        invalid_address: "calculator.zipcode.valid.invalid_address",
      },
    },
  },

  send_calculation_email: {
    form: {
      title: "send_calculation_email.form.title",
      text: "send_calculation_email.form.text",
      email: "send_calculation_email.form.email",
    },
    success: {
      title: "send_calculation_email.success.title",
      text: "send_calculation_email.success.text",
    },
  },

  date: {
    afternoon: "date.afternoon",
    biweekly: "date.biweekly",
    evening: "date.evening",
    four_weekly: "date.four_weekly",
    morning: "date.morning",
    today: "date.today",
    tomorrow: "date.tomorrow",
    weekly: "date.weekly",

    dayparts_addition_to_day: {
      morning: "date.dayparts_addition_to_day.morning",
      afternoon: "date.dayparts_addition_to_day.afternoon",
      evening: "date.dayparts_addition_to_day.evening",
    },

    weekdays: {
      mon: "date.weekdays.mon",
      tue: "date.weekdays.tue",
      wed: "date.weekdays.wed",
      thu: "date.weekdays.thu",
      fri: "date.weekdays.fri",
      sat: "date.weekdays.sat",
      sun: "date.weekdays.sun",
      monday: "date.weekdays.monday",
      tuesday: "date.weekdays.tuesday",
      wednesday: "date.weekdays.wednesday",
      thursday: "date.weekdays.thursday",
      friday: "date.weekdays.friday",
      saturday: "date.weekdays.saturday",
      sunday: "date.weekdays.sunday",
    },

    months: {
      january: "date.months.january",
      february: "date.months.february",
      march: "date.months.march",
      april: "date.months.april",
      may: "date.months.may",
      june: "date.months.june",
      july: "date.months.july",
      august: "date.months.august",
      september: "date.months.september",
      october: "date.months.october",
      november: "date.months.november",
      december: "date.months.december",
    },

    months_shorthand: {
      january: "date.months_shorthand.january",
      february: "date.months_shorthand.february",
      march: "date.months_shorthand.march",
      april: "date.months_shorthand.april",
      may: "date.months_shorthand.may",
      june: "date.months_shorthand.june",
      july: "date.months_shorthand.july",
      august: "date.months_shorthand.august",
      september: "date.months_shorthand.september",
      october: "date.months_shorthand.october",
      november: "date.months_shorthand.november",
      december: "date.months_shorthand.december",
    },

    time_unit: {
      minute: "date.time_unit.minute",
      hour: "date.time_unit.hour",
      day: "date.time_unit.day",
      week: "date.time_unit.week",
      month: "date.time_unit.month",
      year: "date.time_unit.year",

      abbr: {
        hour: "date.time_unit.abbr.hour",
        minute: "date.time_unit.abbr.minute",
      },
    },
  },

  personal_information: {
    title: "personal_information.title",
    subtitle: "personal_information.subtitle",

    form: {
      first_name: "personal_information.form.first_name",
      prefix: "personal_information.form.prefix",
      last_name: "personal_information.form.last_name",
      phone_number: "personal_information.form.phone_number",
      email: "personal_information.form.email",
    },
  },

  info: {
    form: {
      email: "info.form.email",
      message: {
        label: "info.form.message.label",
        text: "info.form.message.text",
      },
      text: {
        label: "info.form.text.label",
        link_label: "info.form.text.link_label",
      },
      title: "info.form.title",
      button: {
        label: "info.form.button.label",
      },
    },

    success: {
      text: "info.success.text",
      title: "info.success.title",
    },
  },

  subscribe_to_waiting_list: {
    form: {
      email: "subscribe_to_waiting_list.form.email",
      full_name: "subscribe_to_waiting_list.form.full_name",
      mobile_number: "subscribe_to_waiting_list.form.mobile_number",
      text: {
        label: "subscribe_to_waiting_list.form.text.label",
        link_label: "subscribe_to_waiting_list.form.text.link_label",
      },
      title: "subscribe_to_waiting_list.form.title",

      button: {
        label: "subscribe_to_waiting_list.form.button.label",
      },
    },

    success: {
      text: "subscribe_to_waiting_list.success.text",
      title: "subscribe_to_waiting_list.success.title",
    },
  },

  summary: {
    next: "summary.next",
    title: "summary.title",

    personal_info: {
      header: "summary.personal_info.header",
    },

    cleaning_address: {
      header: "summary.cleaning_address.header",
    },

    subscription: {
      header: "summary.subscription.header",
    },

    payment_info: {
      header: "summary.payment_info.header",

      invoice: {
        description: "summary.payment_info.invoice.description",
        labels: {
          text1: "summary.payment_info.invoice.labels.text1",
          text2: "summary.payment_info.invoice.labels.text2",
          text3: "summary.payment_info.invoice.labels.text3",
        },
      },

      automatic_collection: {
        description: "summary.payment_info.automatic_collection.description",
        labels: {
          text1: "summary.payment_info.automatic_collection.labels.text1",
          text2: "summary.payment_info.automatic_collection.labels.text2",
          text3: "summary.payment_info.automatic_collection.labels.text3",
        },
        iban: {
          label: "summary.payment_info.automatic_collection.iban.label",
          checkbox: {
            label: "summary.payment_info.automatic_collection.iban.checkbox.label",
          },
        },
      },
    },

    terms: {
      header: "summary.terms.header",

      conditions: {
        part1: {
          label: "summary.terms.conditions.part1.label",
          link_label: "summary.terms.conditions.part1.link_label",
        },
        part2: {
          label: "summary.terms.conditions.part2.label",
          link_label: "summary.terms.conditions.part2.link_label",
        },
      },

      privacy: {
        label: "summary.terms.privacy.label",
        link_label: "summary.terms.privacy.link_label",
      },
    },
  },

  share: {
    form: {
      text: "share.form.text",
      title: "share.form.title",

      about: {
        full_name: "share.form.about.full_name",
        header: "share.form.about.header",
      },

      button: {
        label: "share.form.button.label",
      },

      share: {
        email: "share.form.share.email",
        header: "share.form.share.header",
        message: "share.form.share.message",
      },
    },

    success: {
      text: "share.success.text",
      title: "share.success.title",
    },
  },

  whats_next: {
    header: "whats_next.header",

    hero: {
      title: "whats_next.hero.title",
    },

    mail: {
      header: "whats_next.mail.header",
      text: "whats_next.mail.text",
    },

    interview: {
      header: "whats_next.interview.header",
      text: "whats_next.interview.text",
    },
  },
};

export default TranslationMapper;
