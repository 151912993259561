import IconInfo from "assets/icon-info.svg";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import CalculatorProvider from "providers/calculatorProvider";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RootState } from "store/reducers/rootReducer";
import ICalculationPriceEmployerProps, {
  ICalculationPriceEmployerStateProps,
} from "./interfaces/ICalculationPriceEmployerProps";
import ICalculationPriceEmployerState from "./interfaces/ICalculationPriceEmployerState";

class CalculationPriceEmployer extends Component<ICalculationPriceEmployerProps, ICalculationPriceEmployerState> {
  public constructor(props: ICalculationPriceEmployerProps) {
    super(props);
    this.state = this.defaultState();
  }

  private defaultState(): ICalculationPriceEmployerState {
    const state: ICalculationPriceEmployerState = {
      isPriceUpdated: false,
    };

    return state;
  }

  public render(): JSX.Element {
    const textPercentage: string = LanguageProvider.t(TranslationMapper.calculation.employer.info.percentage).replace(
      "{replacePercentage}",
      this.props.calculation.percentageEmployer?.toString() ?? ""
    );
    const textMax: string = LanguageProvider.t(TranslationMapper.calculation.employer.info.max).replace(
      "{replaceMax}",
      CalculatorProvider.getValuta(this.props.calculation.employerMaxMonthlyCompensation) ?? ""
    );
    return (
      <div className="calculation__price d-flex flex-column">
        <div className="order-2 order-lg-1">
          <div className={`h1 mb-0 mb-lg-2 d-flex updated_animation${this.props.isColorGray ? " text-gray-400" : ""}`}>
            {this.props.isPriceLoading && (
              <div className="spinner-border-container">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">
                    {LanguageProvider.t(TranslationMapper.global.loading)}
                    ...
                  </span>
                </div>
              </div>
            )}
            {!this.props.isPriceLoading && (
              <span
                className={`d-flex justify-space-between updated_animation__container${
                  this.state.isPriceUpdated ? " updated_animation__container--is-updated" : ""
                }`}
                key={this.props.calculation.costEmployee}
              >
                <span>{CalculatorProvider.getValuta(this.props.calculation.costEmployer)}</span>
              </span>
            )}
          </div>

          {this.props.isEveryLongShown && (
            <p className="mb-0">{LanguageProvider.t(TranslationMapper.calculation.employer.every.long)}</p>
          )}

          {!this.props.isEveryLongShown && (
            <>
              <p className="mb-0 d-none d-lg-block">
                {LanguageProvider.t(TranslationMapper.calculation.employer.every.short)}
              </p>
              <p className="mb-0 d-lg-none">{LanguageProvider.t(TranslationMapper.calculation.employer.every.long)}</p>
            </>
          )}
        </div>

        {this.props.isEmployerTextShown && (
          <label className="col-12 form-check-label d-flex justify-content-start align-items-start mb-3 mb-lg-0 mt-lg-4 order-1 order-lg-2">
            <img src={IconInfo} className="icon icon__md" alt="" />
            <div className="ps-2">
              <p className="mb-2 d-flex align-items-center pe-5">{textPercentage}%</p>
              <p className="mb-0 d-flex align-items-center pe-5">{textMax}</p>
            </div>
          </label>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): ICalculationPriceEmployerStateProps => ({
  calculation: state.registrationState.calculation,
  isPriceLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.PriceChecking),
});

export default connect(mapStateToProps)(withTranslation()(CalculationPriceEmployer));
