export class LocalStorageUtils {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public static storeItem(key: string, value: any): void {
    if (key && value) {
      localStorage.setItem(key, value);
    }
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public static getItem(key: string): any | null {
    return localStorage.getItem(key);
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  public static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public static storeEncValue(fragment: string): void {
    const params = new URLSearchParams(fragment);
    const encValue = params.get("#c");
    LocalStorageUtils.storeItem(LocalStorageUtils.encValueName, encValue);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public static getEncValue(): any | null {
    return LocalStorageUtils.getItem(LocalStorageUtils.encValueName);
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  public static removeEncValue(): void {
    LocalStorageUtils.removeItem(LocalStorageUtils.encValueName);
  }

  private static readonly encValueName: string = "enc_value";
}
