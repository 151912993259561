import { ApplicationConfig } from "../config";
import IFeatureFlag from "../interfaces/IFeatureFlag";
import BaseClient from "./baseClient";

export default class FlagClient extends BaseClient {
  private readonly endpoint: string = "Flag";

  public constructor() {
    super(ApplicationConfig.endpoints.globalApi);
  }

  public async getFlagsByNameAsync(flagNames: string[]): Promise<IFeatureFlag[]> {
    return await this.postWithResponse<IFeatureFlag[]>(`${this.endpoint}`, flagNames);
  }
}
