import LoaderTypes from "../../enums/loaderTypes";
import { ActionTypes } from "../actionTypes";
import { actionCreator, AppAction } from "../appAction";

export const loadingStart = actionCreator<ActionTypes.LOADING_START, LoaderTypes>(ActionTypes.LOADING_START);

export const loadingFinish = actionCreator<ActionTypes.LOADING_FINISH, LoaderTypes>(ActionTypes.LOADING_FINISH);

export function setAsLoading(loader: LoaderTypes): AppAction {
  return (dispatch) => {
    dispatch(loadingStart(loader));
  };
}

export function unsetAsLoading(loader: LoaderTypes): AppAction {
  return (dispatch) => {
    dispatch(loadingFinish(loader));
  };
}
