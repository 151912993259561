import HeroImage from "assets/hero-error-md.jpg";
import HeroImageXS from "assets/hero-error-xs.jpg";
import IconEmail from "assets/icon-email.svg";
import IconServicedesk from "assets/icon-servicedesk.svg";
import Hero from "components/hero/hero";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RootState } from "store/reducers/rootReducer";
import IErrorPageProps, { IErrorPageStateProps } from "./interfaces/IErrorPageProps";

import IconRotateLeft from "assets/icon-rotate-left.svg";
import Button from "components/material/button/button";

class ErrorPage extends Component<IErrorPageProps> {
  public toStep(path: string | undefined, search?: string): void {
    if (!path) {
      return;
    }

    this.props.history.push({
      pathname: path,
      search,
    });
  }

  public render(): JSX.Element {
    const messageArray = this.props.error.error?.message.split(/\r?\n/) ?? [];
    let title: string | undefined = messageArray[0] ?? undefined;
    title = title
      ? `${LanguageProvider.t(TranslationMapper.global.error.hero.title.default)} ${title}`
      : LanguageProvider.t(TranslationMapper.global.error.hero.title.fallback);

    const contentArray = messageArray.splice(1);
    const message = contentArray.join("\r\n") ?? undefined;

    const errorCode = this.props.error.errorCode
      ? `${LanguageProvider.t(TranslationMapper.global.error.hero.label)} ${this.props.error.errorCode}`
      : undefined;

    return (
      <div className="row">
        <div className="col mb-lg-md">
          <Hero image={HeroImage} imageXS={HeroImageXS} label={errorCode} title={title} type="primary" />
        </div>
        {message && (
          <div className="col-12 mb-3">
            <p>{message}</p>
          </div>
        )}
        {this.props.error.errorBody && (
          <div className="col-12 mb-3">
            <p>{this.props.error.errorBody}</p>
          </div>
        )}
        <div className="col-12">
          <h2>{LanguageProvider.t(TranslationMapper.account.error.header)}</h2>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-sm-6 mb-3">
              <a href="tel:+31880025552" className="btn btn-gray-400 btn--content w-100">
                <span className="btn--content__content">
                  <span>
                    <h3>{`${LanguageProvider.t(TranslationMapper.account.error.button_1.label)} >`}</h3>
                    <p>088-0025552</p>
                  </span>
                  <img src={IconServicedesk} alt="" className="ms-4 icon icon__lg" />
                </span>
              </a>
            </div>
            <div className="col-12 col-sm-6">
              <a
                href={`mailto:info@zizo.nl?subject=${LanguageProvider.t(
                  TranslationMapper.account.error.mailto.subject
                ).replaceAll("{errorCode}", `${errorCode || ""}`)}&body=${LanguageProvider.t(
                  TranslationMapper.account.error.mailto.body
                )
                  .replaceAll("{errorCode}", `${errorCode || ""}`)
                  .replaceAll("{errorTitle}", `${title}`)}`}
                className="btn btn-gray-400 btn--content w-100"
              >
                <span className="btn--content__content">
                  <span>
                    <h3>{`${LanguageProvider.t(TranslationMapper.account.error.button_2.label)} >`}</h3>
                    <p>info@zizo.nl</p>
                  </span>
                  <img src={IconEmail} alt="" className="ms-4 icon icon__lg" />
                </span>
              </a>
            </div>
          </div>
        </div>
        {this.props.link && this.props.link.path && (
          <div className="col-12">
            <hr />
            <Button
              label={this.props.link.label}
              className="btn-link ps-0"
              icon={IconRotateLeft}
              onClick={(): void => this.toStep(this.props.link?.path, this.props.link?.search)}
            ></Button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IErrorPageStateProps => ({
  error: state.errorState,
});

export default connect(mapStateToProps)(withRouter(withTranslation()(ErrorPage)));
