import { IRegistrationCalculatorAdviceProps } from "components/calculatorAdvice/interfaces/ICalculatorAdviceProps";
import HomeType from "enums/homeType";
import {
  IRegistrationCalculationAdviceProps,
  IRegistrationCalculationProps,
  IRegistrationWizardAddressProps,
  IRegistrationWizardCalculatorProps,
  IRegistrationWizardPersonalInformationProps,
  IRegistrationWizardSummaryProps,
  IRegistrationWizardZipCodeProps,
} from "interfaces/IRegistrationWizard";
import { CleaningFrequency } from "pages/calculator/enums/cleaningFrequency";
import CalculatorProvider from "providers/calculatorProvider";

export interface IRegistrationStoreState {
  address: IRegistrationWizardAddressProps;
  calculation: IRegistrationCalculationProps;
  calculationAdvice: IRegistrationCalculationAdviceProps;
  calculator: IRegistrationWizardCalculatorProps;
  calculatorAdvice: IRegistrationCalculatorAdviceProps;
  isModalSendCalculationEmailShown: boolean;
  personalInformation: IRegistrationWizardPersonalInformationProps;
  summary: IRegistrationWizardSummaryProps;
  zipCode: IRegistrationWizardZipCodeProps;
}

export const initialRegistrationStoreState: IRegistrationStoreState = {
  address: {
    city: "",
    houseNumber: 0,
    street: "",
    zipCode: "",
  },
  calculator: {
    calculation: {
      cleaningFrequency: CleaningFrequency.Weekly,
      houseSurface: 1,
      houseType: HomeType.ResidentialHouse,
      includeIroning: false,
      selectedWeeklyHours: CalculatorProvider.durationDefault,
      selectedWeeklyIroningHours: CalculatorProvider.durationIroningDefault,
      useSelectedWeeklyHoursInCalculation: true,
    },
    discount: {
      availableFrequencies: [],
      calculatorType: null,
      zizoCodeErrorCode: null,
    },
    isValid: false,
    isZipCodeValid: false,
  },
  calculation: {
    costEmployee: 0,
    costEmployer: 0,
    estimatedWeeklyHours: 1.5,
    employerMaxMonthlyCompensation: 0,
    percentageEmployer: 0,
  },
  calculationAdvice: {
    costEmployee: 0,
    estimatedWeeklyHours: 1.5,
  },
  calculatorAdvice: {
    isModalCalculatorAdviceShown: false,
    livingRoom: 0,
    bedroom: 0,
    entrance: 0,
    kitchen: 0,
    bathroom: 0,
    toilet: 0,
    landing: 0,
    otherRooms: 0,
  },
  isModalSendCalculationEmailShown: false,
  personalInformation: {
    isValid: false,
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
  },
  summary: {
    payment: { iban: "", automaticCollection: false },
    isValid: false,
    directDebt: false,
    termsOfDelivery: false,
    privacyPolicy: false,
  },
  zipCode: {
    zipCode: "",
    houseNumber: 0,
  },
};
