export const SiteUrls = {
  WorkProgram: "https://zizo.nl/werkprogramma/",
  TermsOfService: "https://zizo.nl/dienstenvoorwaarden/",
  TermsOfPlatform: "https://zizo.nl/platformvoorwaarden/",
  PrivacyPolicy: "https://zizo.nl/privacy-policy/",
  ZizoHome: "https://zizo.nl",
  ZizoContact: "https://zizo.nl/contact",
  en: {
    PrivacyPolicy: "https://zizo.nl/privacy-policy-eng/",
    TermsOfService: "https://zizo.nl/dienstenvoorwaarden-eng/",
    TermsOfPlatform: "https://zizo.nl/platformvoorwaarden-eng/",
  },
};
