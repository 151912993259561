import { Store, configureStore } from "@reduxjs/toolkit";
import { ActionTypesCollection } from "./actionTypes";
import rootReducer, { RootState } from "./reducers/rootReducer";
import { initialAccountState } from "./state/accountState";
import { initialAsideState } from "./state/asideState";
import { initialErrorState } from "./state/errorStoreState";
import { initialFunctionalFlageStoreState } from "./state/featureFlagStoreState";
import { initialLoaderStoreState } from "./state/loaderState";
import { initialRegistrationStoreState } from "./state/registrationState";
import { initialUnitPriceStoreState } from "./state/unitPriceState";

export const initialStates: RootState = {
  accountState: initialAccountState,
  asideState: initialAsideState,
  errorState: initialErrorState,
  featureFlagState: initialFunctionalFlageStoreState,
  loaderState: initialLoaderStoreState,
  registrationState: initialRegistrationStoreState,
  unitPriceState: initialUnitPriceStoreState,
};

class StoreFactory {
  public static Create(): Store<RootState, ActionTypesCollection> {
    return configureStore({
      reducer: rootReducer,
      preloadedState: initialStates,
    });
  }
}

export default StoreFactory;
