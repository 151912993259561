import IconPositionTypes from "enums/iconPositionType";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import IButtonProps from "./interfaces/IButtonProps";

class Button extends Component<IButtonProps> {
  public constructor(props: IButtonProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private onClick(eventArgs: any): void {
    this.props.onClick && this.props.onClick(eventArgs);
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  private get className(): string {
    let className = "btn";

    if (this.props.className != null) {
      className += ` ${this.props.className}`;
    }

    return className;
  }

  public renderIconTextToggle(): JSX.Element {
    //   icon show xs, label not shown sm
    return (
      <>
        {this.props.label && <span className="d-none d-sm-inline">{this.props.label}</span>}
        {this.props.icon && (
          <img
            src={this.props.icon !== true ? this.props.icon : ""}
            alt={this.props.label || ""}
            className="d-sm-none"
          />
        )}
      </>
    );
  }

  public renderDefault(): JSX.Element {
    // Icon start
    if (this.props.iconPosition === IconPositionTypes.Start) {
      return (
        <>
          {this.props.icon && (
            <img
              src={this.props.icon !== true ? this.props.icon : ""}
              alt={this.props.label || ""}
              className={`${this.props.label !== "" ? "me-2" : ""}`}
            />
          )}
          {this.props.label && <span>{this.props.label}</span>}
        </>
      );
    }

    // Icon End
    return (
      <>
        {this.props.label && <span>{this.props.label}</span>}
        {this.props.icon && (
          <img
            src={this.props.icon !== true ? this.props.icon : ""}
            alt={this.props.label || ""}
            className={`${this.props.label !== "" ? "ms-2" : ""}`}
          />
        )}
      </>
    );
  }

  public render(): JSX.Element {
    return (
      <button
        className={this.className}
        disabled={this.props.disabled}
        id={this.props.id}
        onClick={this.onClick}
        type={this.props.type || "button"}
        form={this.props.form || undefined}
      >
        {this.props.iconTextToggle && this.renderIconTextToggle()}
        {!this.props.iconTextToggle && this.renderDefault()}

        {this.props.isLoading && (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">
              {LanguageProvider.t(TranslationMapper.global.loading)}
              ...
            </span>
          </div>
        )}
      </button>
    );
  }
}

export default Button;
