export class SessionStorageUtils {
  public static storeItem(key: string, value: any): void {
    if (key && value) {
      sessionStorage.setItem(key, value);
    }
  }

  public static getItem(key: string): any | null {
    return sessionStorage.getItem(key);
  }

  public static removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }
}
