import Footer from "components/footer/footer";
import { DataLayerEvents, DataLayerNames } from "constants/dataLayerEvent";
import { StorageItems } from "constants/storageItems";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { RoutingPath } from "providers/routingProvider";
import SessionStorageProvider from "providers/sessionProvider";
import { ChangeEvent, Component } from "react";
import { connect } from "react-redux";
import { setUpdateDataPersonalInformation } from "store/actions/registrationActions";
import { RootState } from "store/reducers/rootReducer";
import InputValidator from "utils/inputValidator";
import { ScriptUtil } from "utils/scriptUtil";
import {
  IPersonalInformationDispatchProps,
  IPersonalInformationProps,
  IPersonalInformationStateProps,
} from "./interfaces/IPersonalInformationProps";
import { IPersonalInformationState } from "./interfaces/IPersonalInformationState";

class PersonalInformation extends Component<IPersonalInformationProps, IPersonalInformationState> {
  public constructor(props: IPersonalInformationProps) {
    super(props);

    this.state = this.defaultState();

    this.toStep = this.toStep.bind(this);
    this.onFirstNameChange = this.onFirstNameChange.bind(this);
    this.onPrefixChange = this.onPrefixChange.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
    this.onEmailAddressChange = this.onEmailAddressChange.bind(this);
  }

  private defaultState(): IPersonalInformationState {
    const state: IPersonalInformationState = {
      emailAddress: this.props.personalInformation.emailAddress,
      firstName: this.props.personalInformation.firstName,
      isValid: this.props.personalInformation.isValid,
      lastName: this.props.personalInformation.lastName,
      phoneNumber: this.props.personalInformation.phoneNumber,
      prefix: this.props.personalInformation.prefix,
      isAmpersandWarningShownFirstName: false,
      isAmpersandWarningShownPrefix: false,
      isAmpersandWarningShownLastName: false,
    };

    return state;
  }

  public componentDidMount(): void {
    ScriptUtil.dataLayerPush({
      coupon: this.props.discountCode,
      currency: "EUR",
      event: DataLayerEvents.Step2PersonalInformationStart,
      step: 2,
      step_name: DataLayerNames.Step2PersonalInformation,
      value: this.props.costEmployee,
    });
  }

  public toStep(path: string): void {
    this.props.history.push({
      pathname: path,
    });
  }

  private get data(): IPersonalInformationState {
    return {
      firstName: this.state.firstName,
      prefix: this.state.prefix,
      isValid: this.state.isValid,
      lastName: this.state.lastName,
      phoneNumber: this.state.phoneNumber,
      emailAddress: this.state.emailAddress,
    };
  }

  private onFirstNameChange(event: ChangeEvent<HTMLInputElement>): void {
    const isValid = InputValidator.doesNotContainAmpersand(event.target.value);
    event.target.setCustomValidity(isValid ? "" : "error");

    this.setState({
      isAmpersandWarningShownFirstName: !isValid,
      firstName: event.target.value,
    });
  }

  private onPrefixChange(event: ChangeEvent<HTMLInputElement>): void {
    const isValid = InputValidator.doesNotContainAmpersand(event.target.value);
    event.target.setCustomValidity(isValid ? "" : "error");

    this.setState({
      isAmpersandWarningShownPrefix: !isValid,
      prefix: event.target.value,
    });
  }

  private onChangeLastName(event: ChangeEvent<HTMLInputElement>): void {
    const isValid = InputValidator.doesNotContainAmpersand(event.target.value);
    event.target.setCustomValidity(isValid ? "" : "error");

    this.setState({
      isAmpersandWarningShownLastName: !isValid,
      lastName: event.target.value,
    });
  }

  private onPhoneNumberChange(event: ChangeEvent<HTMLInputElement>): void {
    const value = InputValidator.cleanPhoneNumber(event.target.value);
    this.setState({
      phoneNumber: value || "",
    });
  }

  private onEmailAddressChange(event: ChangeEvent<HTMLInputElement>): void {
    // Custom email check
    const isEmailValid = InputValidator.isEmailAddressValid(event.target.value);
    event.target.setCustomValidity(isEmailValid ? "" : "error");

    this.setState({
      emailAddress: event.target.value,
    });
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private handleSubmit(e: any): void {
    const form = e.target;
    e.preventDefault();
    e.stopPropagation();

    form.classList.add("was-validated");

    if (form.checkValidity()) {
      this.setState({ isValid: true }, () => {
        SessionStorageProvider.set(StorageItems.PersonalInformation, this.data);
        this.props.updateDataPersonalInformation(this.data);
        this.toStep(RoutingPath.summary);

        ScriptUtil.dataLayerPush({
          coupon: this.props.discountCode,
          currency: "EUR",
          event: DataLayerEvents.Step2PersonalInformationSubmitted,
          step: 2,
          step_name: DataLayerNames.Step2PersonalInformation,
          value: this.props.costEmployee,
        });
      });
    }
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  public render(): JSX.Element {
    return (
      <form
        noValidate
        onSubmit={(e): void => this.handleSubmit(e)}
        className="needs-validation"
        id="form-personal-information"
      >
        <div className="row">
          <div className="col">
            <h1 className="mb-lg-lg">{LanguageProvider.t(TranslationMapper.personal_information.title)}</h1>
          </div>
        </div>
        <h2>{LanguageProvider.t(TranslationMapper.personal_information.subtitle)}</h2>
        <div className="mb-3 row">
          <label htmlFor="firstName" className="col-sm-4 col-form-label d-none d-sm-block">
            {LanguageProvider.t(TranslationMapper.personal_information.form.first_name)}*
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control form-control--placeholder-sm-none"
              name="firstName"
              id="firstName"
              placeholder={LanguageProvider.t(TranslationMapper.personal_information.form.first_name)}
              value={this.state.firstName}
              required
              onChange={this.onFirstNameChange}
            />
            {this.state.isAmpersandWarningShownFirstName && (
              <div className="invalid-feedback">
                {LanguageProvider.t(TranslationMapper.global.errors.ampersand_error)}
              </div>
            )}
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="prefix" className="col-sm-4 col-form-label d-none d-sm-block">
            {LanguageProvider.t(TranslationMapper.personal_information.form.prefix)}
          </label>
          <div className="col">
            <input
              type="text"
              className="form-control form-control--placeholder-sm-none"
              name="prefix"
              id="prefix"
              placeholder={LanguageProvider.t(TranslationMapper.personal_information.form.prefix)}
              value={this.state.prefix}
              onChange={this.onPrefixChange}
            />
            {this.state.isAmpersandWarningShownPrefix && (
              <div className="invalid-feedback">
                {LanguageProvider.t(TranslationMapper.global.errors.ampersand_error)}
              </div>
            )}
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="lastName" className="col-sm-4 col-form-label d-none d-sm-block">
            {LanguageProvider.t(TranslationMapper.personal_information.form.last_name)}*
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control form-control--placeholder-sm-none"
              name="lastName"
              id="lastName"
              placeholder={LanguageProvider.t(TranslationMapper.personal_information.form.last_name)}
              required
              value={this.state.lastName}
              onChange={this.onChangeLastName}
            />
            {this.state.isAmpersandWarningShownLastName && (
              <div className="invalid-feedback">
                {LanguageProvider.t(TranslationMapper.global.errors.ampersand_error)}
              </div>
            )}
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="phoneNumber" className="col-sm-4 col-form-label d-none d-sm-block">
            {LanguageProvider.t(TranslationMapper.personal_information.form.phone_number)}*
          </label>
          <div className="col-sm-8">
            <input
              type="tel"
              className="form-control form-control--placeholder-sm-none"
              name="phoneNumber"
              id="phoneNumber"
              placeholder={LanguageProvider.t(TranslationMapper.personal_information.form.phone_number)}
              required
              value={this.state.phoneNumber}
              onChange={this.onPhoneNumberChange}
              pattern="[0-9]{10}"
              minLength={10}
              maxLength={10}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="email" className="col-sm-4 col-form-label d-none d-sm-block">
            {LanguageProvider.t(TranslationMapper.personal_information.form.email)}*
          </label>
          <div className="col-sm-8">
            <input
              type="email"
              className="form-control form-control--placeholder-sm-none"
              name="email"
              id="email"
              placeholder={LanguageProvider.t(TranslationMapper.personal_information.form.email)}
              required
              value={this.state.emailAddress}
              onChange={this.onEmailAddressChange}
            />
          </div>
        </div>
        <Footer
          contentStartButton={{
            onClick: () => this.toStep(RoutingPath.home),
          }}
          contentEndButton={{
            type: "submit",
          }}
        />
      </form>
    );
  }
}

const mapStateToProps = (state: RootState): IPersonalInformationStateProps => ({
  discountCode: state.registrationState.calculator.calculation.discountCode,
  costEmployee: state.registrationState.calculation.costEmployee,
  personalInformation: state.registrationState.personalInformation,
});

const mapDispatchToProps: IPersonalInformationDispatchProps = {
  updateDataPersonalInformation: setUpdateDataPersonalInformation,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformation);
