import produce from "immer";
import { IUnitPriceStoreState, initialUnitPriceStoreState } from "store/state/unitPriceState";
import { ActionTypes, ActionTypesCollection } from "../actionTypes";

export default function unitPriceReducer(
  state: IUnitPriceStoreState = initialUnitPriceStoreState,
  action: ActionTypesCollection
): IUnitPriceStoreState {
  switch (action.type) {
    case ActionTypes.UPDATED_DATA_CLEANER_HOURLY_RATE:
      return produce(state, (draftState) => {
        draftState.cleanerHourlyRate = action.payload;
      });
    case ActionTypes.INITIALIZE_UNIT_PRICE_STATE_FROM_LOCAL_STORAGE:
      return {
        ...state,
        ...action.payload, // Replace the entire state with the updated values
      };

    default:
      return state;
  }
}
