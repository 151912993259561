import BtnAppleAppStore from "assets/btn-apple-app-store.svg";
import BtnGooglePlayStore from "assets/btn-google-play-store.svg";
import HeroImage from "assets/hero-success.jpg";
import IconPhoneInHand from "assets/icon-phone-in-hand.svg";
import Hero from "components/hero/hero";
import { StorageItems } from "constants/storageItems";
import ErrorPage from "error/pages/errorPage";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import SessionStorageProvider from "providers/sessionProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { registerAccountRegistrationUserAsync } from "store/actions/accountActions";
import { SessionStorageUtils } from "utils/sessionStorageUtils";
import AccountLoader from "./accountLoader";
import IAccountCreatedProps, { IAccountCreatedDispatchProps } from "./interfaces/IAccountCreatedProps";
import { IAccountCreatedState } from "./interfaces/IAccountCreatedState";
import IAccountRegistrationUserRequest from "./interfaces/IAccountRegistrationUserRequest";

class AccountCreated extends Component<IAccountCreatedProps, IAccountCreatedState> {
  public constructor(props: IAccountCreatedProps) {
    super(props);

    this.state = this.defaultState();
  }

  private defaultState(): IAccountCreatedState {
    const state: IAccountCreatedState = {
      isError: null,
    };

    return state;
  }

  public componentDidMount(): void {
    this.completeRegistrationAsync();
  }

  private get data(): IAccountRegistrationUserRequest {
    const accountRegistration = SessionStorageProvider.get(StorageItems.AccountRegistration);

    return {
      debtorNumber: accountRegistration ? accountRegistration.externalDebtorId : null,
      endUserEmail: accountRegistration ? accountRegistration.email : null,
      preferredLanguage: LanguageProvider.language,
      accountRegistrationRequestId: accountRegistration ? accountRegistration.accountRegistrationRequestId : null,
    };
  }

  private async completeRegistrationAsync(): Promise<void> {
    const accountRegistration = this.data;

    if (accountRegistration?.accountRegistrationRequestId != null) {
      this.props.onRegisterAccountRegistrationUserAsync(
        accountRegistration,
        () => {
          this.setState({ isError: false });
          SessionStorageUtils.removeItem(StorageItems.AccountRegistration);
        },
        () => {
          this.setState({ isError: true });
        }
      );
    } else {
      this.setState({ isError: true });
    }
  }

  public renderSuccess(): JSX.Element {
    return (
      <div className="row">
        <div className="col mb-lg-md">
          <Hero image={HeroImage} title={LanguageProvider.t(TranslationMapper.account.success.hero.title)} />
        </div>
        <div className="col-12">
          <h2>{LanguageProvider.t(TranslationMapper.account.success.header)}</h2>
        </div>
        <div className="col-12 mb-lg-md">
          <h3 className="d-flex align-items-center">
            <img src={IconPhoneInHand} className="icon icon__md" alt="" />
            {LanguageProvider.t(TranslationMapper.account.success.download.header)}
          </h3>
          <p className="mb-0">{LanguageProvider.t(TranslationMapper.account.success.download.text)}</p>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <div className="row">
            <div className="col">
              <a
                href="https://play.google.com/store/apps/details?id=nl.csu.zizo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={BtnGooglePlayStore} alt="Zizo App - Google Play Store" className="account-success-btn" />
              </a>
            </div>
            <div className="col">
              <a
                href="https://apps.apple.com/nl/app/zizo-de-gemaksapp-voor-thuis/id1590987841"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={BtnAppleAppStore} alt="Zizo App - Apple Store" className="account-success-btn" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        {this.state.isError === null && <AccountLoader />}
        {this.state.isError === true && <ErrorPage />}
        {this.state.isError === false && this.renderSuccess()}
      </>
    );
  }
}

const mapDispatchToProps: IAccountCreatedDispatchProps = {
  onRegisterAccountRegistrationUserAsync: registerAccountRegistrationUserAsync,
};

export default connect(null, mapDispatchToProps)(withTranslation()(AccountCreated));
