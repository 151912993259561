import { Component } from "react";
import { IHeroProps } from "./interfaces/IHeroProps";

export default class Hero extends Component<IHeroProps> {
  public render(): JSX.Element {
    return (
      <div className={`hero ${this.props.type === "primary" ? "hero--primary" : "hero--secondary"}`}>
        <picture>
          <source media="(max-width: 991px)" srcSet={this.props.imageXS ?? this.props.image} />
          <source media="(min-width: 992px)" srcSet={this.props.image} />
          <img src={this.props.imageXS} alt="" />
        </picture>
        <div className="content">
          {this.props.label && <span className="label">{this.props.label}</span>}
          <h1>{this.props.title}</h1>
        </div>
      </div>
    );
  }
}
