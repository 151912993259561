import { LocalStorageUtils } from "utils/localStorageUtils";

export default class LocalStorageProvider {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public static set(key: string, value: any, subKey?: string): void {
    if (!subKey) {
      LocalStorageUtils.storeItem(key, JSON.stringify(value));
    } else {
      const originalValue = LocalStorageProvider.get(key);
      originalValue[subKey] = value;
      LocalStorageUtils.storeItem(key, JSON.stringify(originalValue));
    }
  }

  public static get(key: string): any {
    const parsedValues = JSON.parse(LocalStorageUtils.getItem(key)) ?? null;
    return parsedValues;
  }
}
