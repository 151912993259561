import AccountLoader from "components/account/accountLoader";
import { StorageItems } from "constants/storageItems";
import ErrorPage from "error/pages/errorPage";
import { IErrorLinkProps } from "error/pages/interfaces/IErrorPageProps";
import TranslationMapper from "i18n/mapper";
import IAccountRegistrationResponse from "interfaces/IAccountRegistration";
import LanguageProvider from "providers/languageProvider";
import { RoutingPath } from "providers/routingProvider";
import SessionStorageProvider from "providers/sessionProvider";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UserService from "services/userService";
import { fetchAccountRegistrationRequest } from "store/actions/accountActions";
import IAccountCreateProps, { IAccountCreateDispatchProps } from "./interfaces/IAccountCreateProps";
import IAccountGetDataState from "./interfaces/IAccountCreateState";

class AccountCreate extends Component<IAccountCreateProps, IAccountGetDataState> {
  private accountRegistrationRequestId: string | null = "";
  public constructor(props: IAccountCreateProps) {
    super(props);

    this.state = this.defaultState();

    UserService.logoutUser();
  }

  public componentDidMount(): void {
    const search = this.props.location.search;
    this.accountRegistrationRequestId = new URLSearchParams(search).get("id");

    if (!this.accountRegistrationRequestId) {
      this.setState({ isError: true });
      return;
    }

    this.props.fetchAccountRegistrationRequest(
      this.accountRegistrationRequestId,
      (response: IAccountRegistrationResponse) => {
        SessionStorageProvider.set(StorageItems.AccountRegistration, response);
        this.toStep(RoutingPath.accountFinish);
      },
      () => {
        // Display something went wrong message/state
        this.setState({ isError: true });
      }
    );
  }

  private defaultState(): IAccountGetDataState {
    const state: IAccountGetDataState = {
      isError: false,
    };

    return state;
  }

  public toStep(path: string): void {
    this.props.history.push({
      pathname: path,
    });
  }

  private get dataLink(): IErrorLinkProps {
    return {
      label: LanguageProvider.t(TranslationMapper.account.error.button_3.label),
      path: RoutingPath.accountCreateGetData,
      search: `?id=${this.accountRegistrationRequestId?.toUpperCase()}`,
    };
  }

  public render(): JSX.Element {
    return (
      <>
        {!this.state.isError && <AccountLoader />}
        {this.state.isError && <ErrorPage link={this.dataLink} />}
      </>
    );
  }
}

const mapDispatchToProps: IAccountCreateDispatchProps = {
  fetchAccountRegistrationRequest: fetchAccountRegistrationRequest,
};

export default connect(null, mapDispatchToProps)(withRouter(AccountCreate));
