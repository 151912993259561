import * as msal from "@azure/msal-browser";

import { AuthenticationConfig, zizoPolicies } from "../config";

export default class MsalFactory {
  private static msalContext: msal.PublicClientApplication;

  public static initialize(): void {
    this.msalContext = new msal.PublicClientApplication(AuthenticationConfig(zizoPolicies.policyNames.signUp));
  }

  public static getMsalContext(): msal.PublicClientApplication {
    if (!this.msalContext) {
      throw Error("MSAL not initialized.");
    }

    return this.msalContext;
  }

  public static logoutAllAccounts(postLogoutRedirectUri: string | null): void {
    const account = MsalFactory.getMsalContext().getAllAccounts();
    account.forEach((a) => {
      const request: msal.EndSessionRequest = {
        account: a,
      };

      if (postLogoutRedirectUri) {
        request.postLogoutRedirectUri = postLogoutRedirectUri;
      }

      MsalFactory.getMsalContext().logoutRedirect(request);
    });
  }
}
