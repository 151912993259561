import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { connect } from "react-redux";
import { updateUserLanguageSetting } from "store/actions/userActions";
import { RootState } from "store/reducers/rootReducer";

import ILanguageSelectionProps, {
  ILanguageSelectionDispatchProps,
  ILanguageSelectionStateProps,
} from "./interfaces/ILanguageSelectionProps";
import ILanguageSelectionState from "./interfaces/ILanguageSelectionState";

class LanguageSelection extends Component<ILanguageSelectionProps, ILanguageSelectionState> {
  public constructor(props: ILanguageSelectionProps) {
    super(props);

    const state: ILanguageSelectionState = {
      langKey: LanguageProvider.language,
    };

    this.state = state;

    this.onLanguageChange = this.onLanguageChange.bind(this);
  }

  private onLanguageChange(langKey: string): void {
    this.props.onUpdateLanguage(langKey);
    this.setState({
      langKey: langKey,
    });
    LanguageProvider.language = langKey;
  }

  private get renderLanguageOptions(): JSX.Element {
    return (
      <>
        {LanguageProvider.languages.map((language) => {
          const onClick = (): void => this.onLanguageChange(language.key);

          return (
            <div
              className={`btn btn-sm d-flex align-items-center language__item ${
                language.key === LanguageProvider.language ? " language__item--selected" : ""
              }`}
              key={language.key}
              onClick={onClick}
            >
              <img src={language.flag} alt={language.label} className="language__item__flag" />
              <div className="language__item__label">{language.label}</div>
            </div>
          );
        })}
      </>
    );
  }

  public render(): JSX.Element {
    return <div className="d-flex flex-row-reverse language">{this.renderLanguageOptions}</div>;
  }
}

const mapStateToProps = (state: RootState): ILanguageSelectionStateProps => ({});

const mapDispatchToProps: ILanguageSelectionDispatchProps = {
  onUpdateLanguage: updateUserLanguageSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelection);
