import { StorageItems } from "constants/storageItems";
import SessionStorageProvider from "providers/sessionProvider";
import { ActionTypes } from "store/actionTypes";
import { actionCreator } from "store/appAction";
import { IUnitPriceStoreState, initialUnitPriceStoreState } from "store/state/unitPriceState";

export const initialUnitPriceStateFromLocalStorage = actionCreator<
  ActionTypes.INITIALIZE_UNIT_PRICE_STATE_FROM_LOCAL_STORAGE,
  IUnitPriceStoreState
>(ActionTypes.INITIALIZE_UNIT_PRICE_STATE_FROM_LOCAL_STORAGE);

export function setUnitPriceInitialValuesWithStorage(): any {
  const sessionStorageUnitPrice = SessionStorageProvider.get(StorageItems.UnitPrice) || {};

  const updatedState: IUnitPriceStoreState = {
    cleanerHourlyRate: sessionStorageUnitPrice.cleanerHourlyRate ?? initialUnitPriceStoreState.cleanerHourlyRate,
  };

  // Dispatch an action to update the state
  return (dispatch): void => {
    dispatch(initialUnitPriceStateFromLocalStorage(updatedState));
  };
}
