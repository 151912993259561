import IconGtPrimary from "assets/icon-gt-primary.svg";
import Button from "components/material/button/button";
import UnitPrice from "components/unitPrice/unitPrice";
import { DataLayerEvents, DataLayerNames } from "constants/dataLayerEvent";
import { SiteUrls } from "constants/siteUrl";
import { UnitPrices } from "constants/unitPrice";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { RoutingPath } from "providers/routingProvider";
import { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { clearDiscountCode, validateDiscountCodeAsync } from "store/actions/registrationActions";
import { RootState } from "store/reducers/rootReducer";
import { RecaptchaUtil } from "utils/recaptchaUtil";
import { ScriptUtil } from "utils/scriptUtil";
import { isStringNullOrWhiteSpace, transformStringToHyperlink } from "utils/stringUtils";
import {
  IDiscountDispatchProps,
  IDiscountProps,
  IDiscountStateProps,
  IValidateDiscountCodeRequestProps,
} from "./interfaces/IDiscountProps";
import { IDiscountState } from "./interfaces/IDiscountState";

class Discount extends Component<IDiscountProps, IDiscountState> {
  public constructor(props: IDiscountProps) {
    super(props);

    this.state = this.defaultState();

    this.onDiscountCodeChange = this.onDiscountCodeChange.bind(this);
    this.validateDiscountCodeAsync = this.validateDiscountCodeAsync.bind(this);
    this.continueWithoutCode = this.continueWithoutCode.bind(this);
  }

  public componentDidMount(): void {
    ScriptUtil.dataLayerPush({
      coupon: this.state.discountCode || undefined,
      event: DataLayerEvents.Step0DiscountStart,
      step: 0,
      step_name: DataLayerNames.Step0Discount,
    });
  }

  private defaultState(): IDiscountState {
    const state: IDiscountState = {
      captchaToken: "",
      discountCode: this.getDiscountCodeFromUrl(),
      isDiscountCodeValid: true,
      isFormValidated: false,
    };

    return state;
  }

  private get data(): IValidateDiscountCodeRequestProps {
    return {
      captchaToken: this.state.captchaToken,
      discountCode: this.state.discountCode,
    };
  }

  private get isFormValidated(): boolean {
    return this.state.isFormValidated;
  }

  public toStep(path: string): void {
    this.props.history.push({
      pathname: path,
    });
  }

  private getDiscountCodeFromUrl(): string {
    const urlParams = new URLSearchParams(window.location.search);
    let discountCode = urlParams.get("code");
    if (discountCode == null || isStringNullOrWhiteSpace(discountCode)) {
      discountCode = "";
    }

    return discountCode;
  }

  private onDiscountCodeChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({
      discountCode: event.target.value,
    });
  }

  private continueWithoutCode(): void {
    this.props.clearDiscountCode();
    this.toStep(RoutingPath.home);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private handleSubmit(e: any): void {
    const form = e.target;
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isFormValidated: true });

    if (form.checkValidity()) {
      this.validateDiscountCodeAsync();
    }
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  private async validateDiscountCodeAsync(): Promise<void> {
    if (!this.props.googleReCaptchaProps?.executeRecaptcha) {
      this.validateRecaptchaProps();
      return;
    }

    const captchaToken = await this.props.googleReCaptchaProps.executeRecaptcha();

    this.setState({ captchaToken }, () => {
      this.props.validateDiscountCodeAsync(this.data, (isDiscountCodeValid) => {
        if (!isDiscountCodeValid) {
          this.setState({ isDiscountCodeValid: false });
        } else {
          this.setState({ isDiscountCodeValid: true });
          this.toStep(RoutingPath.home);

          ScriptUtil.dataLayerPush({
            coupon: this.state.discountCode,
            event: DataLayerEvents.Step0DiscountSubmitted,
            step: 0,
            step_name: DataLayerNames.Step0Discount,
          });
        }
      });
    });
  }

  private validateRecaptchaProps(): void {
    if (this.props.googleReCaptchaProps?.executeRecaptcha != null) {
      return;
    }

    if (RecaptchaUtil.recaptchaValidationRetryCount < RecaptchaUtil.maxRecaptchaValidationRetryCount) {
      // Sometimes the recaptcha is still loading in the DOM, we retry to compensate for this.
      // This process can't be completely handled by util, because props won't update in timeout
      window.setTimeout(this.validateDiscountCodeAsync.bind(this), 2000);
      RecaptchaUtil.increaseRetryCount();
      return;
    }

    RecaptchaUtil.throwRecaptchaPropsError("Success.completeRegistration");
    RecaptchaUtil.resetRetryCounter();
  }

  public render(): JSX.Element {
    const text2WithUrl = transformStringToHyperlink(
      LanguageProvider.t(TranslationMapper.discount.text.text2),
      LanguageProvider.t(TranslationMapper.discount.text.link_text),
      SiteUrls.WorkProgram
    );

    const label = this.props.isDiscountCodeValidatingLoading
      ? false
      : LanguageProvider.t(TranslationMapper.discount.form.button.label);

    return (
      <div className="row discount">
        <div className="col-12 mb-lg-lg">
          <h1 className="mb-3">{LanguageProvider.t(TranslationMapper.discount.title).toUpperCase()}</h1>
          <p className="mb-lg-md">
            {LanguageProvider.t(TranslationMapper.discount.text.text1)}
            <UnitPrice type={UnitPrices.CleanerHourlyRate} />
            {". "}
            {text2WithUrl}
          </p>
        </div>
        <div className="col-12">
          <Form
            noValidate
            onSubmit={(e): void => this.handleSubmit(e)}
            className={`needs-validation${this.isFormValidated ? " was-validated" : ""}`}
            id="form-discount"
          >
            <label className="form-check-label text-primary fw-bold mb-2" htmlFor="discountCode">
              {LanguageProvider.t(TranslationMapper.discount.form.label)}
            </label>
            <div className="d-sm-flex">
              <div className="w-100 mb-3 mb-sm-0">
                <input
                  className={`form-control ${this.state.isDiscountCodeValid ? "" : "is-invalid"}`}
                  type="text"
                  name="discountCode"
                  id="discountCode"
                  required
                  value={this.state.discountCode}
                  onChange={this.onDiscountCodeChange}
                />
                <div className="invalid-feedback">
                  {LanguageProvider.t(TranslationMapper.global.errors.validate_discount)}
                </div>
              </div>
              <div className="ps-sm-3">
                <Button
                  className="btn-primary w-100"
                  label={label}
                  type="submit"
                  form="form-discount"
                  isLoading={this.props.isDiscountCodeValidatingLoading}
                  disabled={this.props.isDiscountCodeValidatingLoading}
                ></Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="col-12">
          <hr />
          <div className="d-flex justify-content-end">
            <Button
              className="btn-link btn-sm pe-0"
              label={LanguageProvider.t(TranslationMapper.discount.button.label)}
              onClick={(): void => this.continueWithoutCode()}
              icon={IconGtPrimary}
            ></Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): IDiscountStateProps => ({
  isDiscountCodeValidatingLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.DiscountCodeValidating),
});

const mapDispatchToProps: IDiscountDispatchProps = {
  validateDiscountCodeAsync: validateDiscountCodeAsync,
  clearDiscountCode: clearDiscountCode,
};

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(withRouter(Discount)));
