import { IErrorProps } from "error/pages/interfaces/IErrorPageProps";
import TranslationMapper from "i18n/mapper";
import i18next from "i18next";
import { ActionTypes } from "store/actionTypes";
import { actionCreator, AppAction } from "store/appAction";

export const updatedErrorData = actionCreator<ActionTypes.UPDATED_DATA_ERROR, IErrorProps | undefined>(
  ActionTypes.UPDATED_DATA_ERROR
);

/* eslint-disable @typescript-eslint/no-explicit-any */
export function setError(data: IErrorProps): AppAction {
  return async (dispatch): Promise<void> => {
    if (data.error) {
      data.error.message = data.error?.message ?? i18next.t(TranslationMapper.global.errors.general);
    }

    dispatch(updatedErrorData(data));
  };
}

export function clearError(): AppAction {
  return async (dispatch): Promise<void> => {
    dispatch(updatedErrorData(undefined));
  };
}
