import HeroImage from "assets/hero-success.jpg";
import IconMailInbox from "assets/icon-mail-inbox.svg";
import IconServicedesk from "assets/icon-servicedesk.svg";
import Hero from "components/hero/hero";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";

export default class WhatsNext extends Component {
  public render(): JSX.Element {
    return (
      <div className="row">
        <div className="col mb-lg-md">
          <Hero image={HeroImage} title={LanguageProvider.t(TranslationMapper.whats_next.hero.title)} />
        </div>
        <div className="col-12">
          <h2>{LanguageProvider.t(TranslationMapper.whats_next.header)}</h2>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12 col-md-6 mb-3">
              <h3 className="d-flex align-items-center">
                <img src={IconMailInbox} className="icon icon__md" alt="" />
                {LanguageProvider.t(TranslationMapper.whats_next.mail.header)}
              </h3>
              <p>{LanguageProvider.t(TranslationMapper.whats_next.mail.text)}</p>
            </div>
            <div className="col-12 col-md-6">
              <h3 className="d-flex align-items-center">
                <img src={IconServicedesk} className="icon icon__md" alt="" />
                {LanguageProvider.t(TranslationMapper.whats_next.interview.header)}
              </h3>
              <p>{LanguageProvider.t(TranslationMapper.whats_next.interview.text)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
