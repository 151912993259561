import { ActionTypes, ActionTypesCollection } from "store/actionTypes";
import { IAccountStoreState, initialAccountState } from "store/state/accountState";

export default function accountReducer(
  state: IAccountStoreState = initialAccountState,
  action: ActionTypesCollection
): IAccountStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_ACCOUNT_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
