import IconGt from "assets/icon-gt.svg";
import Button from "components/material/button/button";
import CalculatorType from "enums/calculatorType";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { RoutingPath } from "providers/routingProvider";
import { Component } from "react";
import { Collapse } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RootState } from "store/reducers/rootReducer";
import CalculationPriceEmployee from "./calculationPriceEmployee";
import CalculationPriceEmployer from "./calculationPriceEmployer";
import CalculationSettings from "./calculationSettings";
import ICalculationProps, { ICalculationStateProps } from "./interfaces/ICalculationProps";
import ICalculationState from "./interfaces/ICalculationState";

class Calculation extends Component<ICalculationProps, ICalculationState> {
  public constructor(props: ICalculationProps) {
    super(props);

    this.state = this.defaultState();

    this.toggleSettingsOnMobile = this.toggleSettingsOnMobile.bind(this);
  }

  private defaultState(): ICalculationState {
    const state: ICalculationState = {
      isCalculationSettingsMobileShown: false,
    };

    return state;
  }

  private get isShown(): boolean {
    return [RoutingPath.home, RoutingPath.personalInformation, RoutingPath.summary].includes(
      this.props.location.pathname
    );
  }

  private get isVisibleLG(): boolean {
    return [RoutingPath.home, RoutingPath.personalInformation].includes(this.props.location.pathname);
  }

  private get isDiscountCodePercentageEnabled(): boolean {
    return this.props.discount.calculatorType === CalculatorType.Default;
  }

  private get currentStep(): number {
    if ([RoutingPath.personalInformation].includes(this.props.location.pathname)) {
      return 2;
    }
    if ([RoutingPath.summary].includes(this.props.location.pathname)) {
      return 3;
    }
    if ([RoutingPath.whatsNext].includes(this.props.location.pathname)) {
      return 4;
    }

    return 1;
  }

  private toggleSettingsOnMobile(): void {
    this.setState({ isCalculationSettingsMobileShown: !this.state.isCalculationSettingsMobileShown });
  }

  public renderEmployer(): JSX.Element {
    return (
      <div className="order-lg-3">
        <h2 className="mb-4">{LanguageProvider.t(TranslationMapper.calculation.employer.title)}</h2>
        <CalculationPriceEmployer isEmployerTextShown={true} />
        <hr className="card--calculation__hr d-lg-none" />
      </div>
    );
  }

  public renderCalculation(): JSX.Element {
    const _iconSummary = this.props.isCreatingAccountLoading ? false : IconGt;

    return (
      <div className={`col-12 col-lg-4${!this.isVisibleLG ? " d-lg-none" : ""}`}>
        <div className="card card--content card--content--rounded-4-5 ms-xl-2 card--calculation">
          <div className="card-body">
            <Collapse in={this.state.isCalculationSettingsMobileShown}>
              <div>
                <div className="d-flex flex-column">
                  {this.isDiscountCodePercentageEnabled && this.renderEmployer()}
                  <div className="order-lg-1">
                    <h2 className="mb-4">{LanguageProvider.t(TranslationMapper.calculation.employee.title)}</h2>
                    <CalculationSettings />
                    {!this.isDiscountCodePercentageEnabled && (
                      <hr className="card--calculation__hr d-none d-lg-block order-lg-2" />
                    )}
                    <div className="d-none d-lg-block">
                      <CalculationPriceEmployee />
                    </div>
                  </div>
                  {this.isDiscountCodePercentageEnabled && (
                    <hr className="card--calculation__hr d-none d-lg-block order-lg-2" />
                  )}
                </div>
              </div>
            </Collapse>
            <div className="d-flex justify-content-between">
              <div className="d-flex d-lg-none">
                <CalculationPriceEmployee
                  toggleSettingsOnMobile={this.toggleSettingsOnMobile}
                  isCalculationSettingsMobileShown={this.state.isCalculationSettingsMobileShown}
                  isReadDataCalculationAllowed={false}
                />
              </div>
              <div className="d-flex align-items-center">
                {this.currentStep === 1 && (
                  <Button
                    className="btn-secondary d-lg-none"
                    form="form-calculator"
                    icon={IconGt}
                    label={LanguageProvider.t(TranslationMapper.calculator.next)}
                    type="submit"
                  />
                )}

                {this.currentStep === 2 && (
                  <Button
                    className="btn-secondary d-lg-none"
                    form="form-personal-information"
                    icon={IconGt}
                    label={LanguageProvider.t(TranslationMapper.global.next)}
                    type="submit"
                  />
                )}

                {this.currentStep === 3 && (
                  <Button
                    className="btn-secondary d-lg-none"
                    form="form-summary"
                    icon={_iconSummary}
                    label={LanguageProvider.t(TranslationMapper.summary.next)}
                    type="submit"
                    isLoading={this.props.isCreatingAccountLoading}
                    disabled={this.props.isCreatingAccountLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public render(): JSX.Element {
    if (!this.isShown) {
      return <></>;
    }

    return this.renderCalculation();
  }
}

const mapStateToProps = (state: RootState): ICalculationStateProps => ({
  isCreatingAccountLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.AccountCreating),
  discount: state.registrationState.calculator.discount,
});

export default connect(mapStateToProps)(withRouter(withTranslation()(Calculation)));
