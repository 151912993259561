export const DataLayerEvents = {
  Step0DiscountStart: "keuze stap 0 - code",
  Step0DiscountSubmitted: "stap 0 afgerond - code",
  Step1CalculatorCleaningHours: "keuze stap 1 - schoonmaak - duur schoonmaak",
  Step1Frequency: "keuze stap 1 - schoonmaak - frequentie",
  Step1CalculatorIroning: "keuze stap 1 - schoonmaak - strijkdienst",
  Step1CalculatorZipCode: "keuze stap 1 - schoonmaak - adres",
  Step1CalculatorSubmitted: "stap 1 afgerond - schoonmaak",
  Step2PersonalInformationStart: "keuze stap 2 - jouw gegevens",
  Step2PersonalInformationSubmitted: "stap 2 afgerond - jouw gegevens",
  Step3SummaryStart: "keuze stap 3 - samenvatting",
  Step3SummarySubmitted: "stap 3 afgerond - samenvatting",
};

export const DataLayerNames = {
  Step0Discount: "korting",
  Step1Cleaning: "schoonmaak",
  Step2PersonalInformation: "jouw gegevens",
  Step3Summary: "samenvatting",
};
