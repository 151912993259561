import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import AccountCreated from "components/account/accountCreated";
import AccountLoader from "components/account/accountLoader";
import { zizoPolicies } from "config";
import { StorageItems } from "constants/storageItems";
import SessionStorageProvider from "providers/sessionProvider";
import { Component } from "react";

export default class AccountFinish extends Component {
  private zizoPoliciesAuthorities(): void {
    if (zizoPolicies.authorities.signUp) {
      const accountRegistration = SessionStorageProvider.get(StorageItems.AccountRegistration);
      zizoPolicies.authorities.signUp.extraQueryParameters = {};
      zizoPolicies.authorities.signUp.extraQueryParameters.email = accountRegistration.email;
    }
  }

  public render(): JSX.Element {
    this.zizoPoliciesAuthorities();

    return (
      <>
        <MsalAuthenticationTemplate
          authenticationRequest={zizoPolicies.authorities.signUp}
          interactionType={InteractionType.Redirect}
        >
          <AccountCreated />
        </MsalAuthenticationTemplate>
        <UnauthenticatedTemplate>
          <AccountLoader />
        </UnauthenticatedTemplate>
      </>
    );
  }
}
