import "./main.scss";

import Header from "components/header/header";
import React from "react";
import { withTranslation } from "react-i18next";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import DateUtils from "utils/dateUtils";

import Aside from "components/aside/aside";
import Calculation from "components/calculation/calculation";
import Progress from "components/progress/progress";
import Elements from "elements/Elements";
import IAppState from "interfaces/IAppState";
import AccountCreate from "pages/account/accountCreate";
import AccountFinish from "pages/account/accountFinish";
import Calculator from "pages/calculator/calculator";
import Discount from "pages/discount/discount";
import PersonalInformation from "pages/personalInformation/personalInformation";
import Summary from "pages/summary/summary";
import WhatsNext from "pages/whats-next/whatsNext";
import EnvironmentUtils from "utils/environmentUtils";
import ErrorPage from "./error/pages/errorPage";
import IAppProps from "./interfaces/IAppProps";
import { RoutingPath } from "./providers/routingProvider";

class App extends React.Component<IAppProps, IAppState> {
  public constructor(props: IAppProps) {
    super(props);

    this.state = this.defaultState();

    DateUtils.setDefaultLocaleGlobally();

    this.setIsAsideShown = this.setIsAsideShown.bind(this);
  }

  private defaultState(): IAppState {
    const state: IAppState = {
      isAsideShown: false,
    };

    return state;
  }

  private get isContentCentered(): boolean {
    return [
      RoutingPath.summary,
      RoutingPath.whatsNext,
      RoutingPath.accountFinish,
      RoutingPath.accountCreateGetData,
      RoutingPath.accountFinish,
      RoutingPath.discount
    ].includes(this.props.location.pathname);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private readonly navRoutes: any = () => (
    <Switch>
      <Route exact path={[RoutingPath.home]} component={Calculator} />
      <Route exact path={RoutingPath.personalInformation} component={PersonalInformation} />
      <Route exact path={RoutingPath.summary} component={Summary} />
      <Route exact path={RoutingPath.whatsNext} component={WhatsNext} />
      <Route exact path={RoutingPath.accountCreateGetData} component={AccountCreate} />
      <Route exact path={RoutingPath.accountFinish} component={AccountFinish} />
      <Route exact path={RoutingPath.discount} component={Discount} />
      <Redirect
        from={RoutingPath.calculator}
        to={{
          pathname: RoutingPath.discount,
          search: window.location.search,
        }}
      />

      <Route exact path={RoutingPath.error} component={ErrorPage} />

      {EnvironmentUtils.isDevEnvironment() && <Route exact path="/elements" component={Elements} />}

      {/* 404 not found component */}
      <Redirect to={RoutingPath.home} />
    </Switch>
  );
  /* eslint-enable @typescript-eslint/no-explicit-any */

  // set aside is show or not
  public setIsAsideShown(isAsideShow: boolean): void {
    if (this.state.isAsideShown !== isAsideShow) {
      this.setState({ isAsideShown: isAsideShow });
    }
  }

  public render(): JSX.Element {
    return (
      <div className="app">
        <Header />
        <Aside checkIsAsideShown={(isAsideShow): void => this.setIsAsideShown(isAsideShow)} />
        <div className="container-fluid container-lg container__content">
          <div className={`row${this.isContentCentered ? " justify-content-lg-center" : ""}`}>
            <div className="col-12 col-lg-8">
              <div className="card card--content card--step me-xl-2">
                <div className={`card-body${!this.state.isAsideShown ? " card-sm-pt-0" : ""}`}>
                  <Progress />
                  {this.navRoutes()}
                </div>
              </div>
            </div>
            <Calculation />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withTranslation()(App));
