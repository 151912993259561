enum LoaderTypes {
    AddressChecking = "AddressChecking",
    AccountCreating = "AccountCreating",
    CalculationEmailSending = "CalculationEmailSending",
    CalculatorAdviceChecking = "CalculatorAdviceChecking",
    InfoSending = "InfoSending",
    PriceChecking = "PriceChecking",
    ShareSending = "ShareSending",
    SubscribeToWaitingListCreating = "SubscribeToWaitingListCreating",
    UnitPriceRetrieving = "UnitPriceRetrieving",
    ZipCodeChecking = "ZipCodeChecking",
    DiscountCodeValidating = "DiscountCodeValidating",
}

export default LoaderTypes;
