import * as msal from "@azure/msal-browser";

export const zizoPolicies: IMsalPolicies = {
  policyNames: {
    signUp: "signUp",
  },
  scopes: {
    signUp: process.env.REACT_APP_signUpB2CScope || "",
  },
  authorities: {
    signUp: {
      authority: `https://${process.env.REACT_APP_B2C_authority}.b2clogin.com/${process.env.REACT_APP_B2C_authority}.onmicrosoft.com/${process.env.REACT_APP_signUpB2CScope}`,
      scopes: [],
    },
  },
  authorityDomain: `${process.env.REACT_APP_B2C_authority}.b2clogin.com`,
};

export function AuthenticationConfig(configName: string): msal.Configuration {
  const authority =
    zizoPolicies.authorities[configName] != null
      ? zizoPolicies.authorities[configName].authority
      : zizoPolicies.authorities.signUp;

  return {
    auth: {
      clientId: process.env.REACT_APP_clientId || "",
      // TODO 4204: No longer set authority based on routing.
      authority: authority,
      knownAuthorities: [zizoPolicies.authorityDomain],
    },
    cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
      storeAuthStateInCookie: false,
      // If you wish to store cache items in cookies as well as browser cache, set storeAuthStateInCookie to "true".
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean): void => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message);
              return;
          }
        },
        piiLoggingEnabled: false,
      },
    },
  };
}

export const ApplicationConfig: IApplicationConfig = {
  endpoints: {
    accountApi: process.env.REACT_APP_endpoints_accountApi || "",
    managementApi: process.env.REACT_APP_endpoints_managementApi || "",
    globalApi: process.env.REACT_APP_endpoints_globalApi || "",
  },
  scopes: {
    signUp: [process.env.REACT_APP_signUpB2CScope || ""],
    accountApi: [process.env.REACT_APP_scopes_accountApi || ""],
  },
  environment: process.env.REACT_APP_environment || "",
  captchaClientKey: process.env.REACT_APP_captcha_clientKey || "",
};

interface IMsalPolicies {
  policyNames: {
    signUp: string;
  };
  scopes: {
    signUp: string;
  };
  authorities: {
    signUp: msal.RedirectRequest;
  };
  authorityDomain: string;
}

interface IApplicationConfig {
  endpoints: {
    [key: string]: string;
  };
  scopes: {
    [key: string]: string[];
  };
  environment: string;
  captchaClientKey: string;
}
