import IDataLayerRequest from "interfaces/IDataLayerRegistrationRequest";
import { CleaningFrequency } from "pages/calculator/enums/cleaningFrequency";
import TagManager from "react-gtm-module";

export class ScriptUtil {
  private static readonly clarityId: string | undefined = process.env.REACT_APP_ClarityId;
  private static readonly googleAnalyticsId: string | undefined = process.env.REACT_APP_GoogleAnalyticsId;

  public static addGoogleAnalyticsScript(): void {
    if (this.googleAnalyticsId === "" || this.googleAnalyticsId?.toLocaleLowerCase() === "test") {
      return;
    }

    const scriptEl = document.createElement("script");
    scriptEl.setAttribute("type", "text/javascript");
    scriptEl.innerHTML = `
        (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
              'gtm.start':
                new Date().getTime(), event: 'gtm.js'
            }); var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${this.googleAnalyticsId}');`;

    document.head.insertBefore(scriptEl, document.head.firstChild);
  }

  public static addGoogleTagManager(): void {
    if (this.googleAnalyticsId === "" || this.googleAnalyticsId?.toLocaleLowerCase() === "test") {
      return;
    }

    let noScriptEl = document.getElementsByTagName("noscript")?.[0];

    if (!noScriptEl) {
      noScriptEl = document.createElement("noscript");
      document.body.insertBefore(noScriptEl, document.body.firstChild);
    }

    const iframeEl = document.createElement("iframe");
    iframeEl.setAttribute("src", `https://www.googletagmanager.com/ns.html?id=${this.googleAnalyticsId}`);
    iframeEl.setAttribute("style", "display:none;visibility:hidden;height:0;width:0");

    noScriptEl.append(iframeEl);
  }

  private static dataLayerFrequencyToString(frequency: CleaningFrequency): string {
    switch (frequency) {
      case 1:
        return "Biweekly";
      case 2:
        return "FourWeekly";
      case 0:
      default:
        return "Weekly";
    }
  }

  public static dataLayerInit(): void {
    const tagManagerArgs = {
      gtmId: this.googleAnalyticsId,
    };

    TagManager.initialize(tagManagerArgs);
  }

  public static dataLayerPush(dataLayer: IDataLayerRequest): void {
    const tagManagerArgs = {
      dataLayer,
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  public static addClarityScript(): void {
    if (this.clarityId === "" || this.clarityId?.toLocaleLowerCase() === "test") {
      return;
    }

    const scriptEl = document.createElement("script");
    scriptEl.setAttribute("type", "text/javascript");
    scriptEl.innerHTML = `
      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);
        t.async=1;
        t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t,y);
        })
        (window, document, 'clarity', 'script', '${this.clarityId}');`;

    document.head.insertBefore(scriptEl, document.head.firstChild);
  }
}
