import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { RoutingPath } from "providers/routingProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { RootState } from "store/reducers/rootReducer";
import { IProgressProps, IProgressStateProps } from "./interfaces/IProgressProps";

class Progress extends Component<IProgressProps> {
  public componentDidMount(): void {
    this.checkCurrentStepIsAllowed();
  }

  public componentDidUpdate(): void {
    this.checkCurrentStepIsAllowed();
  }

  private get isShown(): boolean {
    return [RoutingPath.home, RoutingPath.personalInformation, RoutingPath.summary, RoutingPath.whatsNext].includes(
      this.props.location.pathname
    );
  }

  private get currentStep(): number {
    if ([RoutingPath.personalInformation].includes(this.props.location.pathname)) {
      return 2;
    }
    if ([RoutingPath.summary].includes(this.props.location.pathname)) {
      return 3;
    }
    if ([RoutingPath.whatsNext].includes(this.props.location.pathname)) {
      return 4;
    }

    return 1;
  }

  private checkCurrentStepIsAllowed(): void {
    if (this.currentStep === 2 && (!this.props.isValidCalculation || !this.props.isValidZipCode)) {
      this.toStep(RoutingPath.home);
    } else if (
      this.currentStep === 3 &&
      (!this.props.isValidCalculation || !this.props.isValidPersonalInformation || !this.props.isValidZipCode)
    ) {
      this.toStep(RoutingPath.personalInformation);
    }
  }

  public toStep(path: string): void {
    this.props.history.push({
      pathname: path,
    });
  }

  public renderProgress(): JSX.Element {
    return (
      <ul className="list-inline progress">
        <li
          className={`list-inline-item progress__item${
            this.props.location.pathname === RoutingPath.home ? " progress__item--active" : ""
          }${this.currentStep > 1 ? " progress__item--checked" : ""}`}
        >
          <span className="progress__item--step" data-step="1"></span>
          <span className="progress__item--label">{LanguageProvider.t(TranslationMapper.calculator.title)}</span>
        </li>
        <li
          className={`list-inline-item progress__item${this.currentStep === 2 ? " progress__item--active" : ""}${
            [RoutingPath.summary, RoutingPath.whatsNext].includes(this.props.location.pathname)
              ? " progress__item--checked"
              : ""
          }`}
        >
          <span className="progress__item--step" data-step="2"></span>
          <span className="progress__item--label">
            {LanguageProvider.t(TranslationMapper.personal_information.title)}
          </span>
        </li>
        <li
          className={`list-inline-item progress__item${this.currentStep === 3 ? " progress__item--active" : ""}${
            this.currentStep > 3 ? " progress__item--checked" : ""
          }`}
        >
          <span className="progress__item--step" data-step="3"></span>
          <span className="progress__item--label">{LanguageProvider.t(TranslationMapper.summary.title)}</span>
        </li>
      </ul>
    );
  }

  public render(): JSX.Element {
    if (!this.isShown) {
      return <></>;
    }

    return this.renderProgress();
  }
}

const mapStateToProps = (state: RootState): IProgressStateProps => ({
  isValidCalculation: state.registrationState.calculator.isValid,
  isValidPersonalInformation: state.registrationState.personalInformation.isValid,
  isValidSummary: state.registrationState.summary.isValid,
  isValidZipCode: state.registrationState.zipCode.isZipCodeValid,
});

export default connect(mapStateToProps)(withRouter(withTranslation()(Progress)));
