import { ICalculatorAdviceRequestProps } from "components/calculatorAdvice/interfaces/ICalculatorAdviceProps";
import { IInfoRequestProps } from "components/info/interfaces/IInfoState";
import { ISendCalculationEmailRequestProps } from "components/sendCalculationEmail/interfaces/ISendCalculationEmailProps";
import { IShareRequestProps } from "components/share/interfaces/IShareState";
import { ISubscribeToWaitingListRequestProps } from "components/subscribeToWaitingList/interfaces/ISubscribeToWaitingListProps";
import IAccountRegistrationResponse from "interfaces/IAccountRegistration";
import {
  IRegistrationWizardAddressProps,
  IRegistrationWizardCalculationRequestProps,
  IRegistrationWizardCalculationResponseProps,
  IRegistrationWizardRegisterRequestProps,
} from "interfaces/IRegistrationWizard";
import {
  IValidateDiscountCodeRequestProps,
  IValidateDiscountCodeResponse,
} from "pages/discount/interfaces/IDiscountProps";
import { ApplicationConfig } from "../config";
import BaseClient from "./baseClient";

export default class AccountClient extends BaseClient {
  private readonly registrationEndpoint: string = "registration";
  private readonly accountRegistrationEndpoint: string = "accountRegistration";

  public constructor() {
    super(ApplicationConfig.endpoints.accountApi);
  }

  public async calculateAsync(
    calculationRequest: IRegistrationWizardCalculationRequestProps
  ): Promise<IRegistrationWizardCalculationResponseProps> {
    return this.postWithResponse<IRegistrationWizardCalculationResponseProps>(
      `${this.registrationEndpoint}/calculate`,
      calculationRequest
    );
  }

  public async calculatorAdviceCalculateAsync(
    calculationRequest: ICalculatorAdviceRequestProps
  ): Promise<IRegistrationWizardCalculationResponseProps> {
    return this.postWithResponse<IRegistrationWizardCalculationResponseProps>(
      `${this.registrationEndpoint}/calculate`,
      calculationRequest
    );
  }

  public async getAccountRegistrationRequest(
    accountRegistrationRequestId: string
  ): Promise<IAccountRegistrationResponse> {
    return this.get<IAccountRegistrationResponse>(
      `${this.accountRegistrationEndpoint}/getAccountRegistrationRequest?accountRegistrationRequestId=${accountRegistrationRequestId}`
    );
  }

  public async getAddressAsync(zipCode: string, houseNumber: number): Promise<IRegistrationWizardAddressProps> {
    return this.get<IRegistrationWizardAddressProps>(
      `${this.registrationEndpoint}/getAddress?zipCode=${zipCode}&houseNumber=${houseNumber}`
    );
  }

  public async getCheckActiveRegionAsync(zipCode: string): Promise<boolean> {
    return this.get<boolean>(`${this.registrationEndpoint}/checkActiveRegion?zipCode=${zipCode}`);
  }

  public async contactZizoAsync(infoRequest: IInfoRequestProps): Promise<void> {
    await this.post(`${this.registrationEndpoint}/contactzizo`, infoRequest);
  }

  public async createRegisterAsync(registrationRequest: IRegistrationWizardRegisterRequestProps): Promise<void> {
    await this.post(`${this.registrationEndpoint}/registerNewUser`, registrationRequest);
  }

  public async shareCalculationAsync(shareCalculationRequest: IShareRequestProps): Promise<void> {
    await this.post(`${this.registrationEndpoint}/shareCalculation`, shareCalculationRequest);
  }

  public async sendCalulationMailAsync(sendCalculationEmailRequest: ISendCalculationEmailRequestProps): Promise<void> {
    await this.post(`${this.registrationEndpoint}/sendCalculationEmail`, sendCalculationEmailRequest);
  }

  public async subscribeToWaitingListAsync(
    subscribeToWaitingListRequest: ISubscribeToWaitingListRequestProps
  ): Promise<void> {
    await this.post(`${this.registrationEndpoint}/subscribeToWaitingList`, subscribeToWaitingListRequest);
  }

  public async validateDiscountCodeAsync(
    validateDiscountCodeRequest: IValidateDiscountCodeRequestProps
  ): Promise<IValidateDiscountCodeResponse> {
    return this.postWithResponse(`${this.registrationEndpoint}/verifyZizoCode`, validateDiscountCodeRequest);
  }
}
