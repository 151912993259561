import React, { ErrorInfo } from "react";

import Header from "components/header/header";
import ErrorPage from "error/pages/errorPage";
import { connect } from "react-redux";
import { setError } from "store/actions/errorActions";
import IErrorBoundaryProps, { IErrorBoudaryDispatchProps } from "./interfaces/IErrorBoundaryProps";
import IErrorBoundaryState from "./interfaces/IErrorBoundaryState";

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(error: Error): { hasError: boolean } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    this.props.setError({ error, errorCode: "ZIZO-GR-NHLE" });
  }

  public render(): JSX.Element {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="app">
          <Header />
          <div className="container-fluid container-lg container__content">
            <div className="row justify-content-lg-center">
              <div className="col-12 col-lg-8">
                <div className="card card--content card--step me-xl-2">
                  <div className="card-body card-sm-pt-0">
                    <ErrorPage />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}

const mapDispatchToProps: IErrorBoudaryDispatchProps = {
  setError: setError,
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
