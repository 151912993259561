import ReactNotification from "components/reactNotification/reactNotification";
import TranslationMapper from "i18n/mapper";
import TelemetryService from "services/telemetryService";

export class RecaptchaUtil {
  /* eslint-disable @typescript-eslint/no-inferrable-types */
  public static recaptchaValidationRetryCount: number = 0;
  public static readonly maxRecaptchaValidationRetryCount: number = 4;

  public static increaseRetryCount(): void {
    RecaptchaUtil.recaptchaValidationRetryCount = RecaptchaUtil.recaptchaValidationRetryCount + 1;
  }

  public static resetRetryCounter(): void {
    RecaptchaUtil.recaptchaValidationRetryCount = 0;
  }

  public static throwRecaptchaPropsError(originFunctionDescription: string): void {
    TelemetryService.AppInsights?.trackException({
      exception: {
        name: originFunctionDescription,
        message: "Could not find this.props.googleReCaptchaProps.executeRecaptcha",
      },
    });

    const onErrorMessageClick = (): void => window.location.reload();
    ReactNotification.error(
      TranslationMapper.global.errors.captcha,
      60000,
      true,
      undefined,
      undefined,
      onErrorMessageClick
    );
  }
}
