import { SessionStorageUtils } from "utils/sessionStorageUtils";

export default class SessionStorageProvider {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public static set(key: string, value: any, subKey?: string): void {
    if (!subKey) {
      SessionStorageUtils.storeItem(key, JSON.stringify(value));
    } else {
      const originalValue = SessionStorageProvider.get(key);
      originalValue[subKey] = value;
      SessionStorageUtils.storeItem(key, JSON.stringify(originalValue));
    }
  }

  public static get(key: string): any {
    const parsedValues = JSON.parse(SessionStorageUtils.getItem(key)) ?? null;
    return parsedValues;
  }
}
