import { IAuthenticator } from "authenticators/interfaces/IAuthenticator";

type CRUDOperation = "GET" | "PUT" | "PATCH" | "POST" | "DELETE";

export default abstract class BaseClient {
  /* eslint-disable no-useless-constructor */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public constructor(private readonly resource: string, private readonly authenticator?: IAuthenticator) {}

  public async get<T>(query: string): Promise<T> {
    return this.execute(query, "GET").then((response) => {
      if (!response.ok) {
        return Promise.reject(response.statusText);
      }

      return response.json().then((result) => {
        return result;
      });
    });
  }

  public async post(query: string, data: any): Promise<Response> {
    return this.execute(query, "POST", data);
  }

  public async postWithResponse<T>(query: string, data: any): Promise<T> {
    return this.execute(query, "POST", data).then((r) => r.json());
  }

  public async put(query: string, data: any): Promise<Response> {
    return this.execute(query, "PUT", data);
  }

  public async patch(query: string, data: any): Promise<Response> {
    return this.execute(query, "PATCH", data);
  }

  protected async delete(query: string): Promise<Response> {
    return this.execute(query, "DELETE");
  }

  private async execute(query: string, method: CRUDOperation, data?: any): Promise<Response> {
    const url = `${this.resource}/${query}`;
    // eslint-disable-next-line
    let request: RequestInit = {
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      method,
    };
    if (data) {
      request.body = JSON.stringify(data);
    }

    if (this.authenticator) {
      request = await this.authenticator.authenticateRequestAsync(request);
      const h: any = request.headers;
      if (!h.Authorization || h.Authorization.includes("null")) {
        throw Error("No token found");
      }
    }

    return fetch(url, request).then((response) => {
      if (!response.ok && response.status !== 400) {
        // in case of a Bad Request (http status 400) the response is also returned to the caller
        throw Error(response.statusText);
      } else if (response.status === 400) {
        throw response.status;
      }

      return response;
    });
  }
}
