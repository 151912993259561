import { ApplicationConfig } from "../config";
import IUserClient from "../interfaces/IUserClient";
import BaseClient from "./baseClient";

export default class UserClient extends BaseClient implements IUserClient {
    private readonly endpoint: string = "user";

    public constructor() {
        super(ApplicationConfig.endpoints.api);
    }

    public async ensureAppAccessAsync(): Promise<boolean> {
        return this.get<boolean>(`${this.endpoint}/access`);
    }
}
