import DimensionsTypes from "enums/dimensionsType";

export default class DimensionsProvider {
  public static getDimension(): DimensionsTypes {
    const { innerWidth: width } = window;

    if (width > 1400) {
      return DimensionsTypes.XXL;
    } else if (width > 1200) {
      return DimensionsTypes.XL;
    } else if (width > 992) {
      return DimensionsTypes.LG;
    } else if (width > 768) {
      return DimensionsTypes.MD;
    } else if (width > 576) {
      return DimensionsTypes.SM;
    }

    return DimensionsTypes.XS;
  }

  public static get isBreakpointUpLG(): boolean {
    if ([DimensionsTypes.LG, DimensionsTypes.XL, DimensionsTypes.XXL].includes(DimensionsProvider.getDimension())) {
      return true;
    }

    return false;
  }
}
