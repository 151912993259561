import { IUpsertZipCodeRequestProps } from "components/subscribeToWaitingList/interfaces/ISubscribeToWaitingListProps";
import { ApplicationConfig } from "config";
import BaseClient from "./baseClient";

export default class ManagementClient extends BaseClient {
  private readonly postalCodeRequestEndpoint: string = "PostalCodeRequest";
  private readonly unitPriceEndpoint: string = "UnitPrice";

  public constructor() {
    super(ApplicationConfig.endpoints.managementApi);
  }

  public async getUnitPriceCurrentlyActiveAsync(unitPriceCode: string): Promise<number> {
    return this.get(`${this.unitPriceEndpoint}/currently-active/${unitPriceCode}`);
  }

  public async upsertPostalCodeRequestAsync(request: IUpsertZipCodeRequestProps): Promise<Response> {
    return this.post(`${this.postalCodeRequestEndpoint}`, request);
  }
}
