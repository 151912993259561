import { Component } from "react";
import ICalculatorAdviceRoomTypeProps from "./interfaces/ICalculatorAdviceRoomTypeProps";
import ICalculatorAdviceRoomTypeState from "./interfaces/ICalculatorAdviceRoomTypeState";

export default class CalculatorAdviceRoomType extends Component<
  ICalculatorAdviceRoomTypeProps,
  ICalculatorAdviceRoomTypeState
> {
  public static step: number = 1;
  public static min: number = 0;
  public static max: number = 6;

  public constructor(props: ICalculatorAdviceRoomTypeProps) {
    super(props);

    this.state = this.defaultState();
  }

  private defaultState(): ICalculatorAdviceRoomTypeState {
    const state: ICalculatorAdviceRoomTypeState = {
      amount: this.props.amount,
    };

    return state;
  }

  private get isMax(): boolean {
    return this.state.amount >= CalculatorAdviceRoomType.max || this.props.isMaxDisabled;
  }

  private get isMin(): boolean {
    return this.state.amount <= CalculatorAdviceRoomType.min;
  }

  private handleAmount(increase: boolean): void {
    let amount = this.state.amount;
    if (increase) {
      amount = amount + CalculatorAdviceRoomType.step;
    } else {
      amount = amount - CalculatorAdviceRoomType.step;
    }

    if (amount < CalculatorAdviceRoomType.min) {
      amount = CalculatorAdviceRoomType.min;
    } else if (amount > CalculatorAdviceRoomType.max) {
      amount = CalculatorAdviceRoomType.max;
    }

    this.setState({ amount });

    this.props.onChangeAmount(amount, this.props.roomType);
  }

  public render(): JSX.Element {
    return (
      <div className="col-12 col-lg-6 d-flex justify-content-between align-items-center mb-3 mb-lg-2">
        <div>{this.props.label}</div>
        <div className="d-flex justify-content-between align-items-center amount amount--min-width">
          <div
            className={`btn btn-outline-primary btn-outline-primary--circle${this.isMin ? " disabled" : ""}`}
            onClick={() => this.handleAmount(false)}
          >
            -
          </div>
          <div className="h1">{this.state.amount}</div>
          <div
            className={`btn btn-outline-primary btn-outline-primary--circle${this.isMax ? " disabled" : ""}`}
            onClick={() => this.handleAmount(true)}
          >
            +
          </div>
        </div>
      </div>
    );
  }
}
