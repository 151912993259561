import IconCheck from "assets/icon-check.svg";
import Button from "components/material/button/button";
import ModalZizo from "components/modal/modal";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sendCalculationEmailAsync, setUpdateModalSendCalculationEmailShown } from "store/actions/registrationActions";
import { RootState } from "store/reducers/rootReducer";
import InputValidator from "utils/inputValidator";
import { RecaptchaUtil } from "utils/recaptchaUtil";
import {
  ISendCalculationEmailDispatchProps,
  ISendCalculationEmailProps,
  ISendCalculationEmailRequestProps,
  ISendCalculationEmailStateProps,
} from "./interfaces/ISendCalculationEmailProps";
import { ISendCalculationEmailState } from "./interfaces/ISendCalculationEmailState";

class SendCalculationEmail extends Component<ISendCalculationEmailProps, ISendCalculationEmailState> {
  public constructor(props: ISendCalculationEmailProps) {
    super(props);

    this.state = this.defaultState();

    this.onEmailAddressChange = this.onEmailAddressChange.bind(this);
    this.sendCalculationMailAsync = this.sendCalculationMailAsync.bind(this);
    this.handleSendCalculationEmailHide = this.handleSendCalculationEmailHide.bind(this);
  }

  private defaultState(): ISendCalculationEmailState {
    const state: ISendCalculationEmailState = {
      emailAddress: "",
      captchaToken: "",
      isFormValidated: false,
      isSuccessShown: false,
      isModalSendCalculationEmailShown: false,
    };

    return state;
  }

  private get isFormValidated(): boolean {
    return this.state.isFormValidated;
  }

  private get data(): ISendCalculationEmailRequestProps {
    return {
      cleaningFrequency: this.props.calculation.cleaningFrequency,
      emailAddress: this.state.emailAddress,
      captchaToken: this.state.captchaToken,
      selectedWeeklyHours: this.props.calculation.selectedWeeklyHours,
      selectedWeeklyIroningHours: this.props.calculation.selectedWeeklyIroningHours,
      costEmployee: this.props.calculatedCost,
      includeIroning: this.props.calculation.includeIroning,
    };
  }

  private onEmailAddressChange(event: ChangeEvent<HTMLInputElement>): void {
    const isEmailValid = InputValidator.isEmailAddressValid(event.target.value);
    event.target.setCustomValidity(isEmailValid ? "" : "error");

    this.setState({
      emailAddress: event.target.value,
    });
  }

  private handleSendCalculationEmailHide(): void {
    this.props.updateModalSendCalculationEmailShown(false);
    this.setState(this.defaultState());
  }

  private validateRecaptchaProps(): void {
    if (this.props.googleReCaptchaProps?.executeRecaptcha != null) {
      return;
    }

    if (RecaptchaUtil.recaptchaValidationRetryCount < RecaptchaUtil.maxRecaptchaValidationRetryCount) {
      // Sometimes the recaptcha is still loading in the DOM, we retry to compensate for this.
      // This process can't be completely handled by util, because props won't update in timeout
      window.setTimeout(this.sendCalculationMailAsync.bind(this), 2000);
      RecaptchaUtil.increaseRetryCount();
      return;
    }

    RecaptchaUtil.throwRecaptchaPropsError("Success.completeRegistration");
    RecaptchaUtil.resetRetryCounter();
  }

  private async sendCalculationMailAsync(): Promise<void> {
    if (!this.props.googleReCaptchaProps?.executeRecaptcha) {
      this.validateRecaptchaProps();
      return;
    }

    const captchaToken = await this.props.googleReCaptchaProps.executeRecaptcha();

    this.setState({ captchaToken }, () => {
      this.props.sendCalculationEmailAsync(this.data, () => {
        this.setState({ isSuccessShown: true });

        setTimeout(() => {
          this.handleSendCalculationEmailHide();
        }, 5000);
      });
    });
  }

  public modalFooter(): JSX.Element {
    const _label = this.props.isMailCallLoading
      ? undefined
      : LanguageProvider.t(TranslationMapper.info.form.button.label);
    return (
      <Button
        className="btn-primary w-100"
        label={_label}
        type="submit"
        form="form-send-calc-mail"
        isLoading={this.props.isMailCallLoading}
      ></Button>
    );
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private handleSubmit(e: any): void {
    const form = e.target;

    e.preventDefault();
    e.stopPropagation();

    this.setState({
      isFormValidated: true,
    });

    if (form.checkValidity()) {
      this.sendCalculationMailAsync();
    }
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  public renderForm(): JSX.Element {
    return (
      <>
        <div className="modal__content">
          <h2>{LanguageProvider.t(TranslationMapper.send_calculation_email.form.title).toUpperCase()}</h2>
          <p>{LanguageProvider.t(TranslationMapper.send_calculation_email.form.text)}</p>
        </div>
        <Form
          noValidate
          onSubmit={(e): void => this.handleSubmit(e)}
          className={`needs-validation${this.isFormValidated ? " was-validated" : ""}`}
          id="form-send-calc-mail"
        >
          <Form.Group>
            <Form.Control
              type="email"
              placeholder={`${LanguageProvider.t(TranslationMapper.send_calculation_email.form.email)}*`}
              required
              onChange={this.onEmailAddressChange}
            />
          </Form.Group>
        </Form>
      </>
    );
  }

  public renderSuccess(): JSX.Element {
    return (
      <div className="modal__content">
        <h2 className="d-flex justify-content-center align-items-center">
          {LanguageProvider.t(TranslationMapper.send_calculation_email.success.title)}
          <img src={IconCheck} className="icon icon__md ms-3" alt="" />
        </h2>
        <p>{LanguageProvider.t(TranslationMapper.send_calculation_email.success.text)}</p>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <ModalZizo
        handleClose={(): void => this.handleSendCalculationEmailHide()}
        show={this.props.isModalSendCalculationEmailShown}
        modalFooter={!this.state.isSuccessShown && this.modalFooter()}
      >
        {!this.state.isSuccessShown && this.renderForm()}
        {this.state.isSuccessShown && this.renderSuccess()}
      </ModalZizo>
    );
  }
}

const mapStateToProps = (state: RootState): ISendCalculationEmailStateProps => ({
  calculation: state.registrationState.calculator.calculation,
  calculatedCost: state.registrationState.calculation.costEmployee,
  isModalSendCalculationEmailShown: state.registrationState.isModalSendCalculationEmailShown,
  isMailCallLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.CalculationEmailSending),
});

const mapDispatchToProps: ISendCalculationEmailDispatchProps = {
  sendCalculationEmailAsync: sendCalculationEmailAsync,
  updateModalSendCalculationEmailShown: setUpdateModalSendCalculationEmailShown,
};

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(withRouter(SendCalculationEmail)));
