import IAccountRegistrationUserRequest from "components/account/interfaces/IAccountRegistrationUserRequest";
import TranslationMapper from "i18n/mapper";
import i18next from "i18next";
import IAccountRegistrationResponse, { IAccountRegistrationError } from "interfaces/IAccountRegistration";
import moment from "moment";
import AccountService from "services/accountService";
import TelemetryService from "services/telemetryService";
import { ActionTypes } from "store/actionTypes";
import { actionCreator, AppAction } from "store/appAction";
import { updatedErrorData } from "./errorActions";

export const fetchedAccountRegistrationError = actionCreator<
  ActionTypes.FETCHED_ACCOUNT_ERROR,
  IAccountRegistrationError
>(ActionTypes.FETCHED_ACCOUNT_ERROR);

export function fetchAccountRegistrationRequest(
  requestId: string,
  onSuccess: (response: IAccountRegistrationResponse) => void,
  onError: () => void
): AppAction {
  return (dispatch): void => {
    const accountService = new AccountService();
    accountService
      .getAccountRegistrationRequest(requestId)
      .then((response) => {
        if (!response.hasExpired && !response.hasBeenCompleted) {
          onSuccess(response);
        } else if (response.hasExpired) {
          const expiredDate = moment(response.expirationDate).format("DD MMMM yyyy");
          const expiredDateBody = i18next
            .t(TranslationMapper.global.errors.registration.account_registration_expired.body)
            .replace("{0}", expiredDate.toString());
          dispatch(
            updatedErrorData({
              error: {
                message: i18next.t(TranslationMapper.global.errors.registration.account_registration_expired.message),
                name: "AccountRegistrationActions.fetchAccountRegistrationRequest",
              },
              errorBody: expiredDateBody,
              errorCode: "ZIZO-AC-9GDA",
            })
          );
          onError();
        } else if (response.hasBeenCompleted) {
          dispatch(
            updatedErrorData({
              error: {
                message: i18next.t(TranslationMapper.global.errors.registration.account_registration_completed.message),
                name: "AccountRegistrationActions.fetchAccountRegistrationRequest",
              },
              errorBody: i18next.t(TranslationMapper.global.errors.registration.account_registration_completed.body),
              errorCode: "ZIZO-AC-S89U",
            })
          );
          onError();
        } else {
          dispatch(
            updatedErrorData({
              error: {
                message: i18next.t(
                  TranslationMapper.global.errors.registration.account_registration_invalid_url.message
                ),
                name: "AccountRegistrationActions.fetchAccountRegistrationRequest",
              },
              errorBody: i18next.t(TranslationMapper.global.errors.registration.account_registration_invalid_url.body),
              errorCode: "ZIZO-AC-ETEV",
            })
          );
          onError();
        }
      })
      .catch((error) => {
        dispatch(
          updatedErrorData({
            error: {
              message: i18next.t(TranslationMapper.global.errors.registration.account_registration_general.message),
              name: "AccountRegistrationActions.fetchAccountRegistrationRequest",
            },
            errorCode: "ZIZO-AC-33FI",
          })
        );

        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "AccountRegistrationActions.fetchAccountRegistrationRequest",
            message: `Error on fetching account registration requests ${error}`,
          },
        });
        onError();
      });
  };
}

export function registerAccountRegistrationUserAsync(
  request: IAccountRegistrationUserRequest,
  onSuccess: () => void,
  onError: () => void
): AppAction {
  return (dispatch): void => {
    const accountService = new AccountService();
    accountService
      .registerAccountRegistrationUserAsync(request)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        TelemetryService.AppInsights?.trackException({
          exception: {
            name: "AccountRegistrationActions.registerAccountRegistrationUserAsync",
            message: `Error on registrating account ${error}`,
          },
        });

        dispatch(
          fetchedAccountRegistrationError({
            errorCode: "zizo-acc-004",
            message: {
              title: i18next.t(TranslationMapper.global.errors.registration.account_registration_general.message),
            },
          })
        );
        onError();
      });
  };
}
