import HeroImage from "assets/hero-loader-md.jpg";
import HeroImageXS from "assets/hero-loader-xs.jpg";
import Hero from "components/hero/hero";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";

export default class AccountLoader extends Component {
  public render(): JSX.Element {
    return (
      <div className="row">
        <div className="col mb-lg-md">
          <Hero
            image={HeroImage}
            imageXS={HeroImageXS}
            label={LanguageProvider.t(TranslationMapper.account.loader.hero.label)}
            title={LanguageProvider.t(TranslationMapper.account.loader.hero.title)}
            type="primary"
          />
        </div>
        <div className="col-12">
          <h2 className="d-flex">
            {LanguageProvider.t(TranslationMapper.account.loader.header)}
            <div className="spinner-border ms-3" role="status">
              <span className="visually-hidden">
                {LanguageProvider.t(TranslationMapper.global.loading)}
                ...
              </span>
            </div>
          </h2>
        </div>
      </div>
    );
  }
}
