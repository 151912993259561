export function replaceStringPlaceholders(str: string, ...val: string[]): string {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(`{${index}}`, val[index]);
  }
  return str;
}

export function getHtmlSafeString(string: string): string {
  // Strip all unwanted chars so it can be safely use as html attribute
  return string.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
}

export function transformStringToHyperlink(
  translation: string,
  linkDescription: string,
  url: string,
  className?: string
): JSX.Element {
  // Replace url keyword in the phrase with hyperlink
  // (needs to be JSX instead of string) and description so it's still translated
  const replaceUrlKeyWord = "{replaceUrl}";
  const translationSplit = translation.split(replaceUrlKeyWord);
  const linkJsx: JSX.Element = (
    <a href={url} target="_blank" rel="noreferrer" className={className}>
      {linkDescription}
    </a>
  );

  return (
    <>
      {translationSplit[0]}
      {linkJsx}
      {translationSplit[1] ?? ""}
    </>
  );
}

export function transformStringToBold(translation: string, boldDescription: string, className?: string): JSX.Element {
  // Replace url keyword in the phrase with hyperlink
  // (needs to be JSX instead of string) and description so it's still translated
  const replaceBoldKeyWord = "{replaceBold}";
  const translationSplit = translation.split(replaceBoldKeyWord);
  const linkJsx: JSX.Element = <b className={className}>{boldDescription}</b>;

  return (
    <>
      {translationSplit[0]}
      {linkJsx}
      {translationSplit[1] ?? ""}
    </>
  );
}

export function isStringNullOrWhiteSpace(value?: string): boolean {
  return value == null || value === "";
}
