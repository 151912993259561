import { Component } from "react";
import Modal from "react-bootstrap/Modal";
import IModalProps from "./interfaces/IModalProps";
import IModalState from "./interfaces/IModalState";

export default class ModalZizo extends Component<IModalProps, IModalState> {
  public constructor(props: IModalProps) {
    super(props);

    const state: IModalState = {
      show: false,
    };

    this.state = state;
    this.handleClose = this.handleClose.bind(this);
  }

  private handleClose(): void {
    this.props.handleClose();
  }

  public renderFooter(): JSX.Element {
    return <Modal.Footer>{this.props.modalFooter}</Modal.Footer>;
  }

  public render(): JSX.Element {
    return (
      <>
        <Modal centered={true} size={this.props.size || "sm"} show={this.props.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            {this.props.title && <Modal.Title as="h2">{this.props.title}</Modal.Title>}
          </Modal.Header>
          <Modal.Body>{this.props.children}</Modal.Body>
          {this.props.modalFooter && this.renderFooter()}
        </Modal>
      </>
    );
  }
}
