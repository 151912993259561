import { AuthenticationConfig, zizoPolicies } from "config";
import MsalFactory from "factories/msalFactory";
import UserClient from "../clients/userClient";

export default class UserService {
  private readonly userClient: UserClient = new UserClient();

  public static logoutUser(): void {
    MsalFactory.logoutAllAccounts(AuthenticationConfig(zizoPolicies.policyNames.signUp).auth.redirectUri || null);
  }
}
