import ZizoLogo from "assets/zizo.svg";
import LanguageSelection from "components/languageSelection/languageSelection";
import { SiteUrls } from "constants/siteUrl";
import { Component } from "react";

export default class Header extends Component {
  public render(): JSX.Element {
    return (
      <header className="container-fluid">
        <div className="row header__top">
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div>
              <a href={SiteUrls.ZizoHome}>
                <img className="logo" src={ZizoLogo} alt="Zizo" />
              </a>
            </div>
            <div>
              <LanguageSelection />
            </div>
          </div>
        </div>
      </header>
    );
  }
}
