import { Component } from "react";
import IButtonContentProps from "./interfaces/IButtonContentProps";

export default class ButtonContentSlide extends Component<IButtonContentProps> {
  public render(): JSX.Element {
    return (
      <div className="btn--content-slide" onClick={(): void => this.props.onClick()} id={this.props.id}>
        <div className="btn--content-slide__slide">
          <div className="btn--content-slide__slide__content">
            <h3>{this.props.contentHeader}</h3>
            <p>{this.props.contentText}</p>
          </div>
        </div>
        <div className="btn--content-slide__btn">
          <div className="btn--content-slide__btn__content">
            <img src={this.props.btnIcon} alt="" />
            {this.props.btnLabel}
          </div>
        </div>
      </div>
    );
  }
}
