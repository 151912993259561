import {
  ILoaderStoreState,
  initialLoaderStoreState,
} from "store/state/loaderState";
import { ActionTypes, ActionTypesCollection } from "../actionTypes";

export default function loaderReducer(
  state: ILoaderStoreState = initialLoaderStoreState,
  action: ActionTypesCollection
): ILoaderStoreState {
  switch (action.type) {
    case ActionTypes.LOADING_START: {
      const updatedLoaders = [...state.loaders, action.payload];
      return { ...state, loaders: updatedLoaders };
    }
    case ActionTypes.LOADING_FINISH: {
      const updatedLoaders = state.loaders.filter((l) => l !== action.payload);
      return { ...state, loaders: updatedLoaders };
    }
    default:
      return state;
  }
}
