import IFeatureFlag from "interfaces/IFeatureFlag";

export default class FeatureFlagProvider {
  public static flagState: IFeatureFlag[] = [];

  public static manageFlagChanges(flagStoreState: IFeatureFlag[]): void {
    // Keep up to date with redux, making it possible for unconnected components to use flags
    this.flagState = flagStoreState;
  }

  public static isFeatureActive(flagName: string, flagStoreState?: IFeatureFlag[]): boolean {
    const flags = flagStoreState ?? this.flagState;
    return flags.find((f) => f.flagName === flagName)?.active === true;
  }
}
