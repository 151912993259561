import AccountClient from "clients/accountClient";
import { IInfoRequestProps } from "components/info/interfaces/IInfoState";
import { IShareRequestProps } from "components/share/interfaces/IShareState";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import i18next from "i18next";
import { NotificationManager } from "react-notifications";
import AccountService from "services/accountService";
import { ActionTypes } from "store/actionTypes";
import { AppAction, actionCreator } from "store/appAction";
import { setAsLoading, unsetAsLoading } from "./loaderActions";

export const updatedModalInfoShown = actionCreator<ActionTypes.UPDATED_MODAL_INFO_SHOWN, boolean>(
  ActionTypes.UPDATED_MODAL_INFO_SHOWN
);

export const updatedModalShareShown = actionCreator<ActionTypes.UPDATED_MODAL_SHARE_SHOWN, boolean>(
  ActionTypes.UPDATED_MODAL_SHARE_SHOWN
);

export function setUpdateModalInfoShown(isModalInfoShown: boolean): AppAction {
  return (dispatch): void => {
    dispatch(updatedModalInfoShown(isModalInfoShown));
  };
}

export function setUpdateModalShareShown(isModalInfoShown: boolean): AppAction {
  return (dispatch): void => {
    dispatch(updatedModalShareShown(isModalInfoShown));
  };
}

export function contactZizo(data: IInfoRequestProps, callback: () => void): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.InfoSending));

    const accountService = new AccountClient();
    accountService
      .contactZizoAsync(data)
      .then(() => {
        callback();
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.global.errors.info));
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.InfoSending)));
  };
}

export function shareCalculation(data: IShareRequestProps, callback: () => void): AppAction {
  return (dispatch): void => {
    dispatch(setAsLoading(LoaderTypes.ShareSending));

    const accountService = new AccountService();
    accountService
      .shareCalculationAsync(data)
      .then(() => {
        callback();
      })
      .catch(() => {
        NotificationManager.error(i18next.t(TranslationMapper.global.errors.share));
      })
      .finally(() => dispatch(unsetAsLoading(LoaderTypes.ShareSending)));
  };
}
