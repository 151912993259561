import TranslationMapper from "i18n/mapper";
import moment, { Moment } from "moment";
import LanguageProvider from "providers/languageProvider";

export default class DateUtils {
  private static readonly defaultLocale: string = "nl";

  public static setDefaultLocaleGlobally(): void {
    moment.updateLocale(DateUtils.defaultLocale, {
      longDateFormat: {
        LT: "HH:mm",
        LTS: "HH:mm:ss",
        L: "DD/MM/YYYY",
        LL: "D MMMM YYYY",
        LLL: "D MMMM YYYY HH:mm",
        LLLL: "dddd D MMMM YYYY HH:mm",
      },
    });
  }

  public static getTimeString(date: Date): string {
    return moment(date).format("H:mm");
  }

  public static getDateString(date: Date): string {
    return moment(date).format("DD-MM-YYYY");
  }

  public static getMonthName(date: Date): string {
    const monthLabels = [
      TranslationMapper.date.months.january,
      TranslationMapper.date.months.february,
      TranslationMapper.date.months.march,
      TranslationMapper.date.months.april,
      TranslationMapper.date.months.may,
      TranslationMapper.date.months.june,
      TranslationMapper.date.months.july,
      TranslationMapper.date.months.august,
      TranslationMapper.date.months.september,
      TranslationMapper.date.months.october,
      TranslationMapper.date.months.november,
      TranslationMapper.date.months.december,
    ];

    const monthLabel = monthLabels[date.getMonth()];

    return LanguageProvider.t(monthLabel);
  }

  public static getMonthShortName(date: Date): string {
    const monthLabels = [
      TranslationMapper.date.months_shorthand.january,
      TranslationMapper.date.months_shorthand.february,
      TranslationMapper.date.months_shorthand.march,
      TranslationMapper.date.months_shorthand.april,
      TranslationMapper.date.months_shorthand.may,
      TranslationMapper.date.months_shorthand.june,
      TranslationMapper.date.months_shorthand.july,
      TranslationMapper.date.months_shorthand.august,
      TranslationMapper.date.months_shorthand.september,
      TranslationMapper.date.months_shorthand.october,
      TranslationMapper.date.months_shorthand.november,
      TranslationMapper.date.months_shorthand.december,
    ];

    const monthLabel = monthLabels[date.getMonth()];

    return LanguageProvider.t(monthLabel);
  }

  public static getWeekDayName(date: Date): string {
    const dayLabels = [
      TranslationMapper.date.weekdays.sunday,
      TranslationMapper.date.weekdays.monday,
      TranslationMapper.date.weekdays.tuesday,
      TranslationMapper.date.weekdays.wednesday,
      TranslationMapper.date.weekdays.thursday,
      TranslationMapper.date.weekdays.friday,
      TranslationMapper.date.weekdays.saturday,
    ];

    const dayLabel = dayLabels[date.getDay()];

    return LanguageProvider.t(dayLabel);
  }

  public static getFriendlyDateString(date: Date): string {
    const formatDate = (d: Moment | Date): string => moment(d).format("D");

    return `${formatDate(date)} ${this.getMonthShortName(date).toLowerCase()}`;
  }

  public static getFriendlyDateTimeString(date: Date): string {
    const formatDate = (d: Moment | Date): string => moment(d).format("D");
    const formattedTime = this.getTimeString(date);

    return `${formatDate(date)} ${this.getMonthName(date).toLowerCase()} ${formattedTime}`;
  }

  public static getTimeDifferenceInMinutes(start: Date, end: Date): number {
    const duration = moment.duration(moment(end).diff(moment(start)));
    return duration.asMinutes();
  }

  public static utcToLocal(date: string | Date): Date {
    return moment.utc(date).local().toDate();
  }

  public static formatDateToYearMonthDay(date?: Date): string | undefined {
    if (!date) {
      return undefined;
    }

    return moment(date).format("yyyy-MM-DD");
  }

  public static addDays(date: Date, days: number): Date {
    return moment(date).add(days, "days").toDate();
  }

  public static getStartOfDay(date: Date): Date {
    return moment(date).startOf("day").toDate();
  }

  public static getMinutesFromDecimalTime(decimalTime: number): string {
    decimalTime = decimalTime * 60 * 60;
    const hours = Math.floor(decimalTime / (60 * 60));
    decimalTime = decimalTime - hours * 60 * 60;
    const minutes = Math.floor(decimalTime / 60);
    return minutes.toString();
  }

  public static getHourFromDecimalTime(decimalTime: number): string {
    const time = decimalTime.toString().split(".");
    const hours = time[0];
    return hours.toString();
  }

  public static convertMinutesToMs(minutes: number): number {
    return minutes * 60000;
  }
}
