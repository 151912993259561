export interface IErrorStoreState {
  error: Error | undefined;
  errorBody: string | undefined;
  errorCode: string | undefined;
}

export const initialErrorState: IErrorStoreState = {
  error: undefined,
  errorCode: undefined,
  errorBody: undefined,
};
