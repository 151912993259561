import IconCheck from "assets/icon-check.svg";
import Button from "components/material/button/button";
import ModalZizo from "components/modal/modal";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { connect } from "react-redux";
import { setUpdateModalShareShown, shareCalculation } from "store/actions/asideActions";
import { RootState } from "store/reducers/rootReducer";
import InputValidator from "utils/inputValidator";
import { RecaptchaUtil } from "utils/recaptchaUtil";
import IShareProps, { IShareDispatchProps, IShareStateProps } from "./interfaces/IShareProps";
import IShareState, { IShareRequestProps } from "./interfaces/IShareState";

class Share extends Component<IShareProps, IShareState> {
  public constructor(props: IShareProps) {
    super(props);

    this.state = this.defaultState();

    this.onEmailAddressChange = this.onEmailAddressChange.bind(this);
    this.onFullNameChange = this.onFullNameChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
  }

  private defaultState(): IShareState {
    const state: IShareState = {
      calculationToShare: this.props.calculator.calculation,
      captchaToken: "",
      emailAddress: "",
      fullName: "",
      isFormValidated: false,
      isSuccessShown: false,
      message: "",
      price: 0,
    };

    return state;
  }

  private get isFormValidated(): boolean {
    return this.state.isFormValidated;
  }

  private get data(): IShareRequestProps {
    return {
      calculationToShare: this.props.calculator.calculation,
      captchaToken: this.state.captchaToken,
      emailAddress: this.state.emailAddress,
      fullName: this.state.fullName,
      message: this.state.message,
      price: this.props.calculation.costEmployee,
    };
  }

  private handleShareHide(): void {
    this.props.updateModalShareShown(false);
  }

  private onFullNameChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({
      fullName: event.target.value,
    });
  }

  private onEmailAddressChange(event: ChangeEvent<HTMLInputElement>): void {
    // Custom email check
    const isEmailValid = InputValidator.isEmailAddressValid(event.target.value);
    event.target.setCustomValidity(isEmailValid ? "" : "error");

    this.setState({
      emailAddress: event.target.value,
    });
  }

  private onMessageChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({
      message: event.target.value,
    });
  }

  private validateRecaptchaProps(): void {
    if (this.props.googleReCaptchaProps?.executeRecaptcha != null) {
      return;
    }

    if (RecaptchaUtil.recaptchaValidationRetryCount < RecaptchaUtil.maxRecaptchaValidationRetryCount) {
      // Sometimes the recaptcha is still loading in the DOM, we retry to compensate for this.
      // This process can't be completely handled by util, because props won't update in timeout
      window.setTimeout(this.createShareAsync.bind(this), 2000);
      RecaptchaUtil.increaseRetryCount();
      return;
    }

    RecaptchaUtil.throwRecaptchaPropsError("Success.completeRegistration");
    RecaptchaUtil.resetRetryCounter();
  }

  private async createShareAsync(): Promise<void> {
    if (!this.props.googleReCaptchaProps?.executeRecaptcha) {
      this.validateRecaptchaProps();
      return;
    }

    const captchaToken = await this.props.googleReCaptchaProps.executeRecaptcha();

    this.setState({ captchaToken }, () => {
      this.props.shareCalculation(this.data, () => {
        this.setState({ isSuccessShown: true });

        setTimeout(() => {
          this.setState(this.defaultState());

          this.props.updateModalShareShown(false);
        }, 5000);
      });
    });
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private handleSubmit(e: any): void {
    const form = e.target;

    e.preventDefault();
    e.stopPropagation();

    this.setState({ isFormValidated: true });

    if (form.checkValidity()) {
      this.createShareAsync();
    }
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  public modalFooter(): JSX.Element {
    const _label = this.props.isSharingLoading
      ? undefined
      : LanguageProvider.t(TranslationMapper.share.form.button.label);
    return (
      <Button
        className="btn-primary w-100"
        label={_label}
        type="submit"
        form="form-share"
        isLoading={this.props.isSharingLoading}
      ></Button>
    );
  }

  public renderForm(): JSX.Element {
    return (
      <>
        <div className="modal__content">
          <h2>{LanguageProvider.t(TranslationMapper.share.form.title)}</h2>
          <p>{LanguageProvider.t(TranslationMapper.share.form.text)}</p>
        </div>
        <Form
          noValidate
          onSubmit={(e): void => this.handleSubmit(e)}
          className={`needs-validation${this.isFormValidated ? " was-validated" : ""}`}
          id="form-share"
        >
          <div className="row">
            <div className="col">
              <h3 className="mb-3">{LanguageProvider.t(TranslationMapper.share.form.about.header)}</h3>
            </div>
          </div>
          <Form.Group className="mb-md">
            <Form.Control
              type="text"
              placeholder={`${LanguageProvider.t(TranslationMapper.share.form.about.full_name)}*`}
              required
              onChange={this.onFullNameChange}
            />
          </Form.Group>
          <div className="row">
            <div className="col">
              <h3 className="mb-3">{LanguageProvider.t(TranslationMapper.share.form.share.header)}</h3>
            </div>
          </div>
          <Form.Group className="mb-3">
            <Form.Control
              type="email"
              placeholder={`${LanguageProvider.t(TranslationMapper.share.form.share.email)}*`}
              required
              onChange={this.onEmailAddressChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={`${LanguageProvider.t(TranslationMapper.share.form.share.message)}*`}
              required
              onChange={this.onMessageChange}
            />
          </Form.Group>
        </Form>
      </>
    );
  }

  public renderSuccess(): JSX.Element {
    return (
      <div className="modal__content">
        <h2 className="d-flex justify-content-center align-items-center">
          {LanguageProvider.t(TranslationMapper.share.success.title)}
          <img src={IconCheck} className="icon icon__md ms-3" alt="" />
        </h2>
        <p>{LanguageProvider.t(TranslationMapper.share.success.text)}</p>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <ModalZizo
        handleClose={(): void => this.handleShareHide()}
        show={this.props.isModalShareShown}
        modalFooter={!this.state.isSuccessShown && this.modalFooter()}
      >
        {!this.state.isSuccessShown && this.renderForm()}
        {this.state.isSuccessShown && this.renderSuccess()}
      </ModalZizo>
    );
  }
}

const mapStateToProps = (state: RootState): IShareStateProps => ({
  calculation: state.registrationState.calculation,
  calculator: state.registrationState.calculator,
  isModalShareShown: state.asideState.isModalShareShown,
  isSharingLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.ShareSending),
});

const mapDispatchToProps: IShareDispatchProps = {
  shareCalculation: shareCalculation,
  updateModalShareShown: setUpdateModalShareShown,
};

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(Share));
