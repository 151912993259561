import IconCheck from "assets/icon-check.svg";
import Button from "components/material/button/button";
import ModalZizo from "components/modal/modal";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { subscribeToWaitingListAsync, upsertPostalCodeRequestAsync } from "store/actions/registrationActions";
import { RootState } from "store/reducers/rootReducer";
import InputValidator from "utils/inputValidator";
import { RecaptchaUtil } from "utils/recaptchaUtil";
import { transformStringToBold } from "utils/stringUtils";
import {
  ISubscribeToWaitingListDispatchProps,
  ISubscribeToWaitingListProps,
  ISubscribeToWaitingListRequestProps,
  ISubscribeToWaitingListStateProps,
  IUpsertZipCodeRequestProps,
} from "./interfaces/ISubscribeToWaitingListProps";
import { ISubscribeToWaitingListState } from "./interfaces/ISubscribeToWaitingListState";

class SubscribeToWaitingList extends Component<ISubscribeToWaitingListProps, ISubscribeToWaitingListState> {
  public constructor(props: ISubscribeToWaitingListProps) {
    super(props);

    this.state = this.defaultState();

    this.onEmailAddressChange = this.onEmailAddressChange.bind(this);
    this.onFullNameChange = this.onFullNameChange.bind(this);
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
  }

  private defaultState(): ISubscribeToWaitingListState {
    const state: ISubscribeToWaitingListState = {
      captchaToken: "",
      emailAddress: "",
      fullName: "",
      houseNumber: undefined,
      isSuccessShown: false,
      isModalSubscribeToWaitingListShown: false,
      isFormValidated: false,
      phoneNumber: "",
      zipCode: this.props.zipCode.zipCode,
    };

    return state;
  }

  private get isFormValidated(): boolean {
    return this.state.isFormValidated;
  }

  private get data(): ISubscribeToWaitingListRequestProps {
    return {
      captchaToken: this.state.captchaToken,
      emailAddress: this.state.emailAddress,
      fullName: this.state.fullName,
      houseNumber: this.props.zipCode.houseNumber,
      phoneNumber: this.state.phoneNumber,
      zipCode: this.props.zipCode.zipCode,
    };
  }

  private get dataUpsertZipCode(): IUpsertZipCodeRequestProps {
    return {
      captchaToken: this.state.captchaToken,
      costEmployee: this.props.calculation.costEmployee,
      emailAddress: this.state.emailAddress,
      houseNumber: this.props.zipCode.houseNumber,
      name: this.state.fullName,
      ironing: this.props.calculator.calculation.includeIroning,
      postalCode: this.props.zipCode.zipCode,
      phoneNumber: this.state.phoneNumber,
      requestedTime: this.props.calculator.calculation.selectedWeeklyHours,
      requestedIroningTime: this.props.calculator.calculation.includeIroning
        ? this.props.calculator.calculation.selectedWeeklyIroningHours
        : 0.0,
      status: "Aangevraagd",
      city: this.props.city,
      street: this.props.street,
    };
  }

  public componentDidUpdate(prevProps: Readonly<ISubscribeToWaitingListProps>): void {
    if (
      this.props.zipCode.isZipCodeValid !== prevProps.zipCode.isZipCodeValid &&
      this.props.isZipCodeLoading &&
      this.props.zipCode.isZipCodeValid === false
    ) {
      this.handleSubscribeToWaitingListShow();
    }
  }

  private get isModalSubscribeToWaitingListShown(): boolean {
    return this.state.isModalSubscribeToWaitingListShown || false;
  }

  private handleSubscribeToWaitingListShow(): void {
    this.setState({ isModalSubscribeToWaitingListShown: true });
  }

  private handleSubscribeToWaitingListHide(): void {
    this.setState({ isModalSubscribeToWaitingListShown: false });
  }

  private onEmailAddressChange(event: ChangeEvent<HTMLInputElement>): void {
    // Custom email check
    const isEmailValid = InputValidator.isEmailAddressValid(event.target.value);
    event.target.setCustomValidity(isEmailValid ? "" : "error");

    this.setState({
      emailAddress: event.target.value,
    });
  }

  private onFullNameChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({
      fullName: event.target.value,
    });
  }

  private onPhoneNumberChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({
      phoneNumber: event.target.value,
    });
  }

  public modalFooter(): JSX.Element {
    const _label = this.props.isSubscribeToWaitingListLoading
      ? undefined
      : LanguageProvider.t(TranslationMapper.subscribe_to_waiting_list.form.button.label);
    return (
      <Button
        className="btn-primary w-100"
        label={_label}
        type="submit"
        form="form-keep-informed"
        isLoading={this.props.isSubscribeToWaitingListLoading}
      ></Button>
    );
  }

  private validateRecaptchasubscribeToWaitingListProps(): void {
    if (this.props.googleReCaptchaProps?.executeRecaptcha != null) {
      return;
    }

    if (RecaptchaUtil.recaptchaValidationRetryCount < RecaptchaUtil.maxRecaptchaValidationRetryCount) {
      // Sometimes the recaptcha is still loading in the DOM, we retry to compensate for this.
      // This process can't be completely handled by util, because props won't update in timeout
      window.setTimeout(this.subscribeToWaitingListAsync.bind(this), 2000);
      RecaptchaUtil.increaseRetryCount();
      return;
    }

    RecaptchaUtil.throwRecaptchaPropsError("Success.completeRegistration");
    RecaptchaUtil.resetRetryCounter();
  }

  private validateRecaptchaUpsertPostalCodeRequestProps(): void {
    if (this.props.googleReCaptchaProps?.executeRecaptcha != null) {
      return;
    }

    if (RecaptchaUtil.recaptchaValidationRetryCount < RecaptchaUtil.maxRecaptchaValidationRetryCount) {
      // Sometimes the recaptcha is still loading in the DOM, we retry to compensate for this.
      // This process can't be completely handled by util, because props won't update in timeout
      window.setTimeout(this.upsertPostalCodeRequestAsync.bind(this), 2000);
      RecaptchaUtil.increaseRetryCount();
      return;
    }

    RecaptchaUtil.throwRecaptchaPropsError("Success.completeRegistration");
    RecaptchaUtil.resetRetryCounter();
  }

  private async subscribeToWaitingListAsync(): Promise<void> {
    if (!this.props.googleReCaptchaProps?.executeRecaptcha) {
      this.validateRecaptchasubscribeToWaitingListProps();
      return;
    }

    const captchaToken = await this.props.googleReCaptchaProps.executeRecaptcha();
    this.setState({ captchaToken }, () => {
      this.props.subscribeToWaitingList(this.data, () => {
        this.upsertPostalCodeRequestAsync();
      });
    });
  }

  private async upsertPostalCodeRequestAsync(): Promise<void> {
    if (!this.props.googleReCaptchaProps?.executeRecaptcha) {
      this.validateRecaptchaUpsertPostalCodeRequestProps();
      return;
    }

    const captchaToken = await this.props.googleReCaptchaProps.executeRecaptcha();
    this.setState({ captchaToken }, () => {
      this.props.upsertPostalCodeRequest(this.dataUpsertZipCode, () => {
        this.setState({ isSuccessShown: true });
        setTimeout(() => {
          this.setState(this.defaultState());
        }, 5000);
      });
    });
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private handleSubmit(e: any): void {
    const form = e.target;

    e.preventDefault();
    e.stopPropagation();

    this.setState({ isFormValidated: true });

    if (form.checkValidity()) {
      this.subscribeToWaitingListAsync();
    }
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  public renderForm(): JSX.Element {
    const text = transformStringToBold(
      LanguageProvider.t(TranslationMapper.subscribe_to_waiting_list.form.text.label),
      LanguageProvider.t(TranslationMapper.subscribe_to_waiting_list.form.text.link_label),
      "text-warning"
    );
    return (
      <>
        <div className="modal__content">
          <h2>{LanguageProvider.t(TranslationMapper.subscribe_to_waiting_list.form.title)}</h2>
          <p>{text}</p>
        </div>
        <Form
          noValidate
          onSubmit={(e): void => this.handleSubmit(e)}
          className={`needs-validation${this.isFormValidated ? " was-validated" : ""}`}
          id="form-keep-informed"
        >
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder={`${LanguageProvider.t(TranslationMapper.subscribe_to_waiting_list.form.full_name)}*`}
              required
              value={this.state.fullName}
              onChange={this.onFullNameChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="tel"
              placeholder={`${LanguageProvider.t(TranslationMapper.subscribe_to_waiting_list.form.mobile_number)}*`}
              required
              value={this.state.phoneNumber}
              onChange={this.onPhoneNumberChange}
              pattern="[0-9]{10}"
              minLength={10}
              maxLength={10}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="email"
              placeholder={`${LanguageProvider.t(TranslationMapper.subscribe_to_waiting_list.form.email)}*`}
              required
              value={this.state.emailAddress}
              onChange={this.onEmailAddressChange}
            />
          </Form.Group>
        </Form>
      </>
    );
  }

  public renderSuccess(): JSX.Element {
    return (
      <div className="modal__content">
        <h2 className="d-flex justify-content-center align-items-center">
          {LanguageProvider.t(TranslationMapper.subscribe_to_waiting_list.success.title)}
          <img src={IconCheck} className="icon icon__md ms-3" alt="" />
        </h2>
        <p>{LanguageProvider.t(TranslationMapper.subscribe_to_waiting_list.success.text)}</p>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <ModalZizo
        handleClose={(): void => this.handleSubscribeToWaitingListHide()}
        modalFooter={!this.state.isSuccessShown && this.modalFooter()}
        show={this.isModalSubscribeToWaitingListShown}
      >
        {!this.state.isSuccessShown && this.renderForm()}
        {this.state.isSuccessShown && this.renderSuccess()}
      </ModalZizo>
    );
  }
}

const mapStateToProps = (state: RootState): ISubscribeToWaitingListStateProps => ({
  calculator: state.registrationState.calculator,
  calculation: state.registrationState.calculation,
  isSubscribeToWaitingListLoading: state.loaderState.loaders.some(
    (l) => l === LoaderTypes.SubscribeToWaitingListCreating
  ),
  isZipCodeLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.ZipCodeChecking),
  zipCode: state.registrationState.zipCode,
  city: state.registrationState.address.city,
  street: state.registrationState.address.street,
});

const mapDispatchToProps: ISubscribeToWaitingListDispatchProps = {
  subscribeToWaitingList: subscribeToWaitingListAsync,
  upsertPostalCodeRequest: upsertPostalCodeRequestAsync,
};

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(withRouter(SubscribeToWaitingList)));
