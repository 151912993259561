import * as H from "history";

export const RoutingPath = {
  accountCreateGetData: "/account/create",
  accountFinish: "/account/finish",
  calculator: "/calculator", // old discount path, used for redirect RoutingPath.discount
  discount: "/discount",
  error: "/error",
  home: "/",
  personalInformation: "/personal-information",
  summary: "/summary",
  whatsNext: "/whats-next",
};

export interface IReactRouteStateParams {
  zipCode?: string;
  houseNumber?: number;
}

export default class RoutingProvider {
  public static invokePushNewRoute(
    routeHistoryProps: H.History,
    routePath: string,
    propToPass?: IReactRouteStateParams
  ): void {
    routeHistoryProps.push(routePath, propToPass ?? undefined);
  }

  public static invokePushNewRouteWithRefresh(routeHistoryProps: H.History, routePath: string): void {
    routeHistoryProps.push(routePath);

    window.location.reload();
  }

  public static clearLocationRouteState(routeHistoryProps: H.History): void {
    routeHistoryProps.replace(routeHistoryProps.location.pathname, undefined);
  }

  public static getRouteState(historyLocation: H.Location): IReactRouteStateParams | undefined {
    if (!historyLocation.state) {
      return;
    }
    const state = historyLocation.state;

    if (!this.isValidStateObject(state)) {
      return undefined;
    }
    return state;
  }

  public static tryGetHashedQueryParam(queryParamId: string): string | null {
    // Try and get query params hidden behind fragment (#) for safety
    // Example url: http://registreer.zizo.nl/registratie/offerte#&id=00000000-0000-0000-0000-000000000000&isRegistered=true
    const urlSearchParams = new URLSearchParams(window.location.hash);
    return urlSearchParams.get(queryParamId);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private static isValidStateObject(stateObj: any): stateObj is IReactRouteStateParams {
    // Creates a bit more type safety then a simple cast,
    // eventually we can extend this to add a similar check for the objects
    const isIdValid = stateObj.id === undefined || typeof stateObj.id === "string";
    const isReservationToShowOnMapValid =
      stateObj.reservationToShowOnMap === undefined || typeof stateObj.reservationToShowOnMap === "object";

    return isIdValid && isReservationToShowOnMapValid;
  }
}
