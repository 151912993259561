import IconStopwatch from "assets/icon-stopwatch.svg";
import Button from "components/material/button/button";
import ModalZizo from "components/modal/modal";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import CalculatorProvider from "providers/calculatorProvider";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { Form } from "react-bootstrap";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { connect } from "react-redux";
import {
  getReadCalculationAdvice,
  setUpdateDataCalculatorAdvice,
  setUpdateDataCalculatorAdviceEstimatedWeeklyHoursWeeklyHours,
  setUpdateDataCalculatorSelectedWeeklyHours,
  setUpdateModalCalculatorAdviceShown,
} from "store/actions/registrationActions";
import { RootState } from "store/reducers/rootReducer";
import { RecaptchaUtil } from "utils/recaptchaUtil";
import CalculatorAdviceRoomType from "./CalculatorAdviceRoomType";
import ICalculatorAdviceProps, {
  ICalculatorAdviceDispatchProps,
  ICalculatorAdviceRequestProps,
  ICalculatorAdviceStateProps,
} from "./interfaces/ICalculatorAdviceProps";
import ICalculatorAdviceState from "./interfaces/ICalculatorAdviceState";

class CalculatorAdvice extends Component<ICalculatorAdviceProps, ICalculatorAdviceState> {
  private timer: NodeJS.Timeout;
  private timerAnimation: NodeJS.Timeout;
  private readonly timerDelay: number = 300;

  public constructor(props: ICalculatorAdviceProps) {
    super(props);

    this.state = this.defaultState();

    this.onChangeAmount = this.onChangeAmount.bind(this);
  }

  private defaultState(): ICalculatorAdviceState {
    const state: ICalculatorAdviceState = {
      captchaToken: "",
      isAmountUpdated: false,
      livingRoom: this.props.calculatorAdvice.livingRoom,
      bedroom: this.props.calculatorAdvice.bedroom,
      entrance: this.props.calculatorAdvice.entrance,
      kitchen: this.props.calculatorAdvice.kitchen,
      bathroom: this.props.calculatorAdvice.bathroom,
      toilet: this.props.calculatorAdvice.toilet,
      landing: this.props.calculatorAdvice.landing,
      otherRooms: this.props.calculatorAdvice.otherRooms,
    };

    return state;
  }

  public componentDidUpdate(prevProps: Readonly<ICalculatorAdviceProps>): void {
    // Do animation price after updated and is loaded once
    if (prevProps.isCalculatorAdviceLoading === true && this.props.isCalculatorAdviceLoading === false) {
      clearTimeout(this.timerAnimation);
      this.setState({ isAmountUpdated: true });
      this.timerAnimation = setTimeout(() => {
        this.setState({ isAmountUpdated: false });
      }, 450);
    }

    // update estimatedWeeklyHours in redux state if max duration is exceeded
    if (
      this.isDurationMax &&
      prevProps.calculationAdvice.estimatedWeeklyHours !== this.props.calculationAdvice.estimatedWeeklyHours
    ) {
      this.props.updateDataCalculatorAdviceEstimatedWeeklyHoursWeeklyHours(this.duration);
    }
  }

  private get data(): ICalculatorAdviceRequestProps {
    return {
      captchaToken: this.state.captchaToken,

      // Advice
      livingRoom: this.state.livingRoom,
      bedroom: this.state.bedroom,
      entrance: this.state.entrance,
      kitchen: this.state.kitchen,
      bathroom: this.state.bathroom,
      toilet: this.state.toilet,
      landing: this.state.landing,
      otherRooms: this.state.otherRooms,
    };
  }

  private get isDurationMax(): boolean {
    const durationMax = CalculatorProvider.durationMax - this.props.calculator.calculation.selectedWeeklyIroningHours;
    if (this.props.calculationAdvice.estimatedWeeklyHours >= durationMax) {
      return true;
    }
    return false;
  }

  private get duration(): number {
    if (this.isDurationMax) {
      return CalculatorProvider.durationMax - this.props.calculator.calculation.selectedWeeklyIroningHours;
    }
    return this.props.calculationAdvice.estimatedWeeklyHours;
  }

  private handleCalculatorAdviceHide(): void {
    this.props.updateModalCalculatorAdviceShown(false);
  }

  public onChangeAmount(amount: number, roomType: string): void {
    clearTimeout(this.timer);

    const state = { ...this.state };
    state[roomType] = amount;

    this.setState(state, () => {
      this.timer = setTimeout(() => {
        this.props.updateDataCalculatorAdvice({
          livingRoom: this.state.livingRoom,
          bedroom: this.state.bedroom,
          entrance: this.state.entrance,
          kitchen: this.state.kitchen,
          bathroom: this.state.bathroom,
          toilet: this.state.toilet,
          landing: this.state.landing,
          otherRooms: this.state.otherRooms,
          isModalCalculatorAdviceShown: this.props.calculatorAdvice.isModalCalculatorAdviceShown,
        });

        this.createCalculatorAdviceAsync();
      }, this.timerDelay);
    });
  }

  private validateRecaptchaProps(): void {
    if (this.props.googleReCaptchaProps?.executeRecaptcha != null) {
      return;
    }

    if (RecaptchaUtil.recaptchaValidationRetryCount < RecaptchaUtil.maxRecaptchaValidationRetryCount) {
      // Sometimes the recaptcha is still loading in the DOM, we retry to compensate for this.
      // This process can't be completely handled by util, because props won't update in timeout
      window.setTimeout(this.createCalculatorAdviceAsync.bind(this), 2000);
      RecaptchaUtil.increaseRetryCount();
      return;
    }

    RecaptchaUtil.throwRecaptchaPropsError("Success.completeRegistration");
    RecaptchaUtil.resetRetryCounter();
  }

  private async createCalculatorAdviceAsync(): Promise<void> {
    if (!this.props.googleReCaptchaProps?.executeRecaptcha) {
      this.validateRecaptchaProps();
      return;
    }

    const captchaToken = await this.props.googleReCaptchaProps.executeRecaptcha();

    this.setState({ captchaToken }, () => {
      this.props.readDataCalculationAdvice(this.data);
    });
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private handleSubmit(e: any): void {
    const form = e.target;

    e.preventDefault();
    e.stopPropagation();

    if (form.checkValidity()) {
      this.props.updateDataCalculatorSelectedWeeklyHours(this.props.calculationAdvice.estimatedWeeklyHours);
      this.handleCalculatorAdviceHide();
    }
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  public renderForm(): JSX.Element {
    return (
      <>
        <div className="modal__content">
          <h2>{LanguageProvider.t(TranslationMapper.advice.title)}</h2>
          <p className="mb-0">{LanguageProvider.t(TranslationMapper.advice.text)}</p>
          <hr />
        </div>
        <Form noValidate onSubmit={(e): void => this.handleSubmit(e)} id="form-calculator-advice">
          <div className="row mb-3">
            <CalculatorAdviceRoomType
              amount={this.props.calculatorAdvice.livingRoom}
              label={LanguageProvider.t(TranslationMapper.advice.room_type.living_room.label)}
              isMaxDisabled={this.isDurationMax}
              onChangeAmount={this.onChangeAmount}
              roomType="livingRoom"
            />
            <CalculatorAdviceRoomType
              amount={this.props.calculatorAdvice.bathroom}
              label={LanguageProvider.t(TranslationMapper.advice.room_type.bathroom.label)}
              isMaxDisabled={this.isDurationMax}
              onChangeAmount={this.onChangeAmount}
              roomType="bathroom"
            />
            <CalculatorAdviceRoomType
              amount={this.props.calculatorAdvice.bedroom}
              label={LanguageProvider.t(TranslationMapper.advice.room_type.bedroom.label)}
              isMaxDisabled={this.isDurationMax}
              onChangeAmount={this.onChangeAmount}
              roomType="bedroom"
            />
            <CalculatorAdviceRoomType
              amount={this.props.calculatorAdvice.toilet}
              label={LanguageProvider.t(TranslationMapper.advice.room_type.toilet.label)}
              isMaxDisabled={this.isDurationMax}
              onChangeAmount={this.onChangeAmount}
              roomType="toilet"
            />
            <CalculatorAdviceRoomType
              amount={this.props.calculatorAdvice.entrance}
              label={LanguageProvider.t(TranslationMapper.advice.room_type.entrance.label)}
              isMaxDisabled={this.isDurationMax}
              onChangeAmount={this.onChangeAmount}
              roomType="entrance"
            />
            <CalculatorAdviceRoomType
              amount={this.props.calculatorAdvice.landing}
              label={LanguageProvider.t(TranslationMapper.advice.room_type.landing.label)}
              isMaxDisabled={this.isDurationMax}
              onChangeAmount={this.onChangeAmount}
              roomType="landing"
            />
            <CalculatorAdviceRoomType
              amount={this.props.calculatorAdvice.kitchen}
              label={LanguageProvider.t(TranslationMapper.advice.room_type.kitchen.label)}
              isMaxDisabled={this.isDurationMax}
              onChangeAmount={this.onChangeAmount}
              roomType="kitchen"
            />
            <CalculatorAdviceRoomType
              amount={this.props.calculatorAdvice.otherRooms}
              label={LanguageProvider.t(TranslationMapper.advice.room_type.other_rooms.label)}
              isMaxDisabled={this.isDurationMax}
              onChangeAmount={this.onChangeAmount}
              roomType="otherRooms"
            />
          </div>
          <div className="row">
            <div className="col">
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="mb-0 d-flex justify-content-between align-items-center">
                <div className="form-check-label d-flex justify-content-start me-2 me-md-3 w-100">
                  <img src={IconStopwatch} className="icon icon__lg" alt="" />
                  <p className="ps-2 mb-0 d-flex flex-column justify-content-center">
                    <span className="d-block">{LanguageProvider.t(TranslationMapper.advice.price.label)}</span>
                    <small className="text-gray-600">{LanguageProvider.t(TranslationMapper.advice.price.text)}</small>
                  </p>
                </div>
                <div className="amount amount--min-width-small">
                  <div className="h1 mb-0 mb-lg-2 updated_animation">
                    {this.props.isCalculatorAdviceLoading && (
                      <div className="spinner-border-container spinner-border-container--align-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">
                            {LanguageProvider.t(TranslationMapper.global.loading)}
                            ...
                          </span>
                        </div>
                      </div>
                    )}
                    {!this.props.isCalculatorAdviceLoading && (
                      <span
                        className={`d-flex justify-content-end text-nowrap w-100 updated_animation__container${
                          this.state.isAmountUpdated ? " updated_animation__container--is-updated" : ""
                        }`}
                        key={this.duration}
                      >
                        <span>
                          {this.duration >= 1 && (
                            <>
                              {CalculatorProvider.getHours(this.duration || 0)}
                              <small>{LanguageProvider.t(TranslationMapper.date.time_unit.abbr.hour)}</small>{" "}
                            </>
                          )}
                          {CalculatorProvider.getMinutes(this.duration || 0) !== "0" && (
                            <>
                              {CalculatorProvider.getMinutes(this.duration || 0)}
                              <small>{LanguageProvider.t(TranslationMapper.date.time_unit.abbr.minute)}</small>
                            </>
                          )}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <hr className="mb-0" />
            </div>
          </div>
        </Form>
      </>
    );
  }

  public modalFooter(): JSX.Element {
    return (
      <div className="d-flex justify-content-between w-100">
        <Button
          className="btn-outline-primary"
          label={LanguageProvider.t(TranslationMapper.global.cancel)}
          form="form-calculator-advice"
          onClick={() => this.handleCalculatorAdviceHide()}
        ></Button>
        <Button
          className="btn-primary"
          label={LanguageProvider.t(TranslationMapper.advice.button.label)}
          type="submit"
          form="form-calculator-advice"
          disabled={this.props.isCalculatorAdviceLoading}
        ></Button>
      </div>
    );
  }

  public render() {
    return (
      <ModalZizo
        handleClose={(): void => this.handleCalculatorAdviceHide()}
        show={this.props.calculatorAdvice.isModalCalculatorAdviceShown}
        modalFooter={this.modalFooter()}
        size="lg"
      >
        {this.renderForm()}
      </ModalZizo>
    );
  }
}

const mapStateToProps = (state: RootState): ICalculatorAdviceStateProps => ({
  calculator: state.registrationState.calculator,
  calculationAdvice: state.registrationState.calculationAdvice,
  calculatorAdvice: state.registrationState.calculatorAdvice,
  isCalculatorAdviceLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.CalculatorAdviceChecking),
});

const mapDispatchToProps: ICalculatorAdviceDispatchProps = {
  updateDataCalculatorAdvice: setUpdateDataCalculatorAdvice,
  updateDataCalculatorAdviceEstimatedWeeklyHoursWeeklyHours:
    setUpdateDataCalculatorAdviceEstimatedWeeklyHoursWeeklyHours,
  updateDataCalculatorSelectedWeeklyHours: setUpdateDataCalculatorSelectedWeeklyHours,
  updateModalCalculatorAdviceShown: setUpdateModalCalculatorAdviceShown,
  readDataCalculationAdvice: getReadCalculationAdvice,
};

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(CalculatorAdvice));
