import IAccountRegistrationUserRequest from "components/account/interfaces/IAccountRegistrationUserRequest";
import { AccountApiAuthenticator } from "../authenticators/accountApiAuthenticator";
import { ApplicationConfig } from "../config";
import BaseClient from "./baseClient";

export default class AccountClientAuthorized extends BaseClient {
  private readonly accountRegistrationEndpoint: string = "accountRegistration";

  public constructor() {
    super(ApplicationConfig.endpoints.accountApi, new AccountApiAuthenticator());
  }

  public async registerAccountRegistrationUserAsync(
    accountRegistrationUserRequest: IAccountRegistrationUserRequest
  ): Promise<Response> {
    return this.post(
      `${this.accountRegistrationEndpoint}/registerAccountRegistrationUserAsync`,
      accountRegistrationUserRequest
    );
  }
}
