import { StorageItems } from "constants/storageItems";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import CalculatorProvider from "providers/calculatorProvider";
import LanguageProvider from "providers/languageProvider";
import SessionStorageProvider from "providers/sessionProvider";
import { Component } from "react";
import { connect } from "react-redux";
import { getUnitPriceCurrentlyActive } from "store/actions/registrationActions";
import { RootState } from "store/reducers/rootReducer";
import IUnitPriceProps, { IUnitPriceDispatchProps, IUnitPriceStateProps } from "./interfaces/IUnitPriceProps";
import IUnitPriceState from "./interfaces/IUnitPriceState";

class UnitPrice extends Component<IUnitPriceProps, IUnitPriceState> {
  public constructor(props: IUnitPriceProps) {
    super(props);

    this.state = this.defaultState();
  }

  private defaultState(): IUnitPriceState {
    const state: IUnitPriceState = {};

    return state;
  }

  public componentDidMount(): void {
    if (this.props.unitPrice?.cleanerHourlyRate === 0) {
      this.props.getUnitPriceCurrentlyActive("01");
    }
  }

  public componentDidUpdate(prevProps: Readonly<IUnitPriceProps>): void {
    // Set result of 'getUnitPriceCurrentlyActive' in SessionStorage
    if (prevProps.unitPrice !== this.props.unitPrice) {
      SessionStorageProvider.set(StorageItems.UnitPrice, this.props.unitPrice);
    }
  }

  public renderLoader(): JSX.Element {
    return (
      <span className="unit-price">
        <span className="spinner-border" role="status">
          <span className="visually-hidden">
            {LanguageProvider.t(TranslationMapper.global.loading)}
            ...
          </span>
        </span>
      </span>
    );
  }

  public renderPrice(): JSX.Element {
    const price: string = CalculatorProvider.getValuta(this.props.unitPrice?.cleanerHourlyRate).replace("€", "").trim();
    return <span className="unit-price">{price}</span>;
  }

  public render(): JSX.Element {
    return (
      <>
        {this.props.isUnitPriceLoading && this.renderLoader()}
        {!this.props.isUnitPriceLoading && this.renderPrice()}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IUnitPriceStateProps => ({
  isUnitPriceLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.UnitPriceRetrieving),
  unitPrice: state.unitPriceState,
});

const mapDispatchToProps: IUnitPriceDispatchProps = {
  getUnitPriceCurrentlyActive: getUnitPriceCurrentlyActive,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitPrice);
