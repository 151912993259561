import IconCalendar from "assets/icon-calendar.svg";
import IconCheck from "assets/icon-check.svg";
import IconEdit from "assets/icon-edit.svg";
import IconGt from "assets/icon-gt.svg";
import IconSend from "assets/icon-send.svg";
import IconServicedesk from "assets/icon-servicedesk.svg";
import Button from "components/material/button/button";
import ButtonContentSlide from "components/material/button/buttonContent";
import { Component } from "react";

export default class Elements extends Component {
  public render(): JSX.Element {
    return (
      <div className="container-fluid container-lg container__content">
        <h1>Header.H1</h1>
        <h2>Header.H2</h2>
        <h3>Header.H3</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </p>
        <p className="small">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </p>
        <hr />
        <h2>Buttons</h2>
        <div className="mb-2">
          <div className="btn btn-outline-primary">BTN.outline-primary</div>
          <div className="btn btn-primary">BTN.primary</div>
          <div className="btn btn-secondary">BTN.secondary</div>
          <div className="btn btn-link">BTN.link</div>
          <div className="btn btn-sm btn-light rounded-0">BTN.light</div>
        </div>
        <div>
          <div className="btn btn-gray-400">BTN.gray-400</div>
          <div className="btn btn-gray-400 btn--content">
            <span className="btn--content__content">
              <span>
                <h3>Meer info</h3>
                <p>Hulp bij vragen</p>
              </span>
              <img src={IconServicedesk} alt="" className="ms-4 icon icon__lg" />
            </span>
          </div>
        </div>

        <h3 className="mt-4">Button shadow with content</h3>

        <div style={{ width: "100px" }}>
          <ButtonContentSlide
            btnIcon={IconServicedesk}
            btnLabel="Label"
            contentHeader="Header"
            contentText="Text"
            onClick={(): void => alert("clicked")}
          />
        </div>
        <hr />
        <h2>Form</h2>
        <div className="row mb-3">
          <div className="col-12 col-lg-6 d-flex justify-content-between align-items-center">
            <div>Roomtype</div>
            <div className="d-flex justify-content-between align-items-center amount">
              <div className="btn btn-outline-primary btn-outline-primary--circle">-</div>
              <div className="h1">3</div>
              <div className="btn btn-outline-primary btn-outline-primary--circle">+</div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-between align-items-center">
            <div>Roomtype</div>
            <div className="d-flex justify-content-between align-items-center amount">
              <div className="btn btn-outline-primary btn-outline-primary--circle">-</div>
              <div className="h1">3</div>
              <div className="btn btn-outline-primary btn-outline-primary--circle">+</div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <label className="col-12 col-lg-6 form-check-label d-flex justify-content-start mb-3 mb-lg-0">
            <img src={IconCalendar} className="icon icon__md" alt="" />
            <div className="ps-2">
              <p className="mb-0 d-flex align-items-center">Label</p>
              <Button className="btn-link p-0" label="Link" />
            </div>
          </label>

          <div className="col-12 col-lg-6">
            <div className="d-flex justify-content-between align-items-center amount">
              <div className="btn btn-outline-primary btn-outline-primary--circle disabled">-</div>
              <div className="h1">
                3 <small>uur</small> 30 <small>min.</small>
              </div>
              <div className="btn btn-outline-primary btn-outline-primary--circle">+</div>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="example" className="col-sm-2 col-form-label">
            Label
          </label>
          <div className="col-sm-10">
            <input type="text" className="form-control" id="example" value="Value" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="example2" className="col-sm-2 col-form-label">
            Label (invalid)
          </label>
          <div className="col-sm-10">
            <input type="text" className="form-control is-invalid" id="example2" value="Value" />
          </div>
        </div>
        <div className="mb-3 row">
          <label htmlFor="example3" className="col-sm-2 col-form-label d-none d-sm-block">
            Label (placeholder xs)
          </label>
          <div className="col-sm-10">
            <input
              type="email"
              className="form-control form-control--placeholder-sm-none"
              name="email"
              id="email"
              placeholder="Label"
              required
            />
          </div>
        </div>

        <div className="mb-3 row">
          <div className="col">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Default checkbox
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Checked checkbox
              </label>
            </div>
          </div>
        </div>
        <div className="mb-3 d-lg-flex justify-content-between align-items-center">
          <label className="form-check-label d-flex justify-content-start" htmlFor="inlineRadio1">
            <img src={IconCalendar} className="icon icon__md" alt="" />
            <p className="ps-2 mb-0 d-flex align-items-center">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
            </p>
          </label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="option1"
                checked
              />
              <label className="form-check-label" htmlFor="inlineRadio1">
                Label 1
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="option2"
              />
              <label className="form-check-label" htmlFor="inlineRadio2">
                Label 2
              </label>
            </div>
          </div>
        </div>
        <hr />
        <h2>Information</h2>
        <div className="card card--gray">
          <div className="card-body">
            <div className="form-text mb-4">
              <strong>Label</strong>
            </div>
            <div className="row">
              {[1, 2, 3, 4].map((info) => {
                return (
                  <label className="col-12 col-lg-6 form-check-label card--gray__label">
                    <div className="d-flex">
                      <div className="d-flex justify-content-center align-items-center card--gray__label__img">
                        <img src={IconCalendar} className="icon icon__md" alt="" />
                      </div>
                      <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                      </div>
                    </div>
                  </label>
                );
              })}
            </div>
          </div>
        </div>
        <hr />
        <h2>Zipcode result</h2>
        <div className="card zipcode-checked">
          <div className="card-body">
            <div className="d-flex">
              <div className="d-flex align-items-center">
                <img src={IconCheck} className="icon icon__md me-3" alt="" />
              </div>
              <div>
                <p className="mb-0 zipcode-checked__text">
                  <b>Zizo is actief op jouw adres!</b>
                  <span>De Meule 10, Valkenswaard</span>
                </p>
              </div>
              <div className="ms-auto">
                <div className="btn btn-link">
                  Wijzigen
                  <img src={IconEdit} className="ms-2" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12">
            <img src="https://picsum.photos/1552/700" className="rounded-lg-4-5 mb-5 img-full" alt="" />
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <h3 className="d-flex align-items-center">
                  <img src={IconCalendar} className="icon icon__md" alt="" /> Header
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua.
                </p>
              </div>
              <div className="col-12 col-md-6">
                <h3 className="d-flex align-items-center">
                  <img src={IconCalendar} className="icon icon__md" alt="" /> Header
                </h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <div className="btn btn-link ps-0">
            Berekening bewaren
            <img src={IconSend} alt="" className="ms-2" />
          </div>
          <div className="btn btn-secondary disabled">
            Dit wil ik <img src={IconGt} className="ms-2" alt="" />
          </div>
        </div>
      </div>
    );
  }
}
