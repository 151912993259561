import IconServicedesk from "assets/icon-servicedesk.svg";
import IconUpload from "assets/icon-upload.svg";
import Info from "components/info/info";
import ButtonContentSlide from "components/material/button/buttonContent";
import Share from "components/share/share";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { RoutingPath } from "providers/routingProvider";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setUpdateModalInfoShown, setUpdateModalShareShown } from "store/actions/asideActions";
import { RootState } from "store/reducers/rootReducer";
import { isStringNullOrWhiteSpace } from "utils/stringUtils";
import IAsideProps, { IAsideDispatchProps, IAsideStateProps } from "./interfaces/IAsideProps";
import IAsideState from "./interfaces/IAsideState";

class Aside extends Component<IAsideProps, IAsideState> {
  public constructor(props: IAsideProps) {
    super(props);

    const state: IAsideState = {
      isModalInfoShown: false,
    };

    this.state = state;
  }

  public componentDidMount(): void {
    this.props.checkIsAsideShown(this.isShown);
  }

  public componentDidUpdate(): void {
    this.props.checkIsAsideShown(this.isShown);
  }

  private get isShown(): boolean {
    return [RoutingPath.home, RoutingPath.personalInformation, RoutingPath.summary, RoutingPath.discount].includes(
      this.props.location.pathname
    );
  }

  private get isShareShown(): boolean {
    if (
      [RoutingPath.discount].includes(this.props.location.pathname) ||
      !isStringNullOrWhiteSpace(this.props.discountCode)
    ) {
      return false;
    }
    return true;
  }

  private handleInfoShow(): void {
    this.props.updateModalInfoShown(true);
  }

  private handleShareShow(): void {
    this.props.updateModalShareShown(true);
  }

  public renderAsideHeader(): JSX.Element {
    return (
      <div className="container-fluid aside__header">
        <div className="row d-lg-none">
          <div className="col-12 px-0">
            <div className="btn-group d-flex">
              <button
                type="button"
                className="btn btn-light flex-fill rounded-0"
                onClick={(): void => this.handleInfoShow()}
              >
                <div>
                  <img src={IconServicedesk} className="icon icon__sm" alt="" />
                </div>
                {LanguageProvider.t(TranslationMapper.aside.info.label)}
              </button>
              {this.isShareShown && (
                <button
                  type="button"
                  className="btn btn-light flex-fill rounded-0"
                  onClick={(): void => this.handleShareShow()}
                >
                  <div>
                    <img src={IconUpload} className="icon icon__sm" alt="" />
                  </div>
                  {LanguageProvider.t(TranslationMapper.aside.share.label)}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  public renderAside(): JSX.Element {
    return (
      <aside className="d-none d-lg-flex">
        <div className="aside__buttons">
          <ButtonContentSlide
            btnIcon={IconServicedesk}
            btnLabel={LanguageProvider.t(TranslationMapper.aside.info.label)}
            contentHeader={LanguageProvider.t(TranslationMapper.aside.info.header)}
            contentText={LanguageProvider.t(TranslationMapper.aside.info.text)}
            onClick={(): void => this.handleInfoShow()}
          />

          {this.isShareShown && (
            <ButtonContentSlide
              btnIcon={IconUpload}
              btnLabel={LanguageProvider.t(TranslationMapper.aside.share.label)}
              contentHeader={LanguageProvider.t(TranslationMapper.aside.share.header)}
              contentText={LanguageProvider.t(TranslationMapper.aside.share.text)}
              onClick={(): void => this.handleShareShow()}
            />
          )}
        </div>
      </aside>
    );
  }

  public render(): JSX.Element {
    if (!this.isShown) {
      return <></>;
    }

    return (
      <>
        {this.renderAsideHeader()}
        {this.renderAside()}

        <Info />
        <Share />
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IAsideStateProps => ({
  discountCode: state.registrationState.calculator.calculation.discountCode,
});

const mapDispatchToProps: IAsideDispatchProps = {
  updateModalInfoShown: setUpdateModalInfoShown,
  updateModalShareShown: setUpdateModalShareShown,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Aside)));
