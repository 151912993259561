import { combineReducers } from "redux";

import accountReducer from "./accountReducer";
import asideReducer from "./asideReducer";
import errorReducer from "./errorReducer";
import featureFlagReducer from "./featureFlagReducer";
import loaderReducer from "./loaderReducer";
import registrationReducer from "./registrationReducer";
import unitPriceReducer from "./unitPriceReducer";

const rootReducer = combineReducers({
  accountState: accountReducer,
  asideState: asideReducer,
  errorState: errorReducer,
  featureFlagState: featureFlagReducer,
  loaderState: loaderReducer,
  registrationState: registrationReducer,
  unitPriceState: unitPriceReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
