import { ApplicationConfig } from "../config";

export enum Environment {
  dev = "dev",
  test = "test",
  prod = "prod",
}

export default class EnvironmentUtils {
  private static currentEnvironment: string = ApplicationConfig.environment;

  public static isTestOrDevEnvironment(): boolean {
    return (
      EnvironmentUtils.currentEnvironment === Environment.dev ||
      EnvironmentUtils.currentEnvironment === Environment.test
    );
  }

  public static isDevEnvironment(): boolean {
    return EnvironmentUtils.currentEnvironment === Environment.dev;
  }

  public static getCurrentEnvironment(): string {
    return this.currentEnvironment;
  }
}
