import IconGt from "assets/icon-gt.svg";
import IconLt from "assets/icon-lt-primary.svg";
import Button from "components/material/button/button";
import IconPositionTypes from "enums/iconPositionType";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { Component } from "react";
import { IFooterProps } from "./interfaces/IFooterProps";

export default class Footer extends Component<IFooterProps> {
  private contentStart(): JSX.Element {
    if (this.props.contentStart) {
      return this.props.contentStart;
    }

    const className = this.props.contentStartButton?.className
      ? this.props.contentStartButton?.className
      : "btn-link btn-sm ps-0";
    const label = this.props.contentStartButton?.label
      ? this.props.contentStartButton?.label
      : LanguageProvider.t(TranslationMapper.global.back);
    const icon =
      this.props.contentStartButton?.icon || this.props.contentStartButton?.icon === false
        ? this.props.contentStartButton?.icon
        : IconLt;
    const iconPosition = this.props.contentStartButton?.iconPosition
      ? this.props.contentStartButton?.iconPosition
      : IconPositionTypes.Start;

    const overwrites = { className, label, icon, iconPosition };
    const _props = { ...this.props.contentStartButton, ...overwrites };

    return <Button {..._props}></Button>;
  }

  private contentEnd(): JSX.Element {
    if (this.props.contentEnd) {
      return this.props.contentEnd;
    }

    const className =
      "d-none d-lg-block " +
      (this.props.contentEndButton?.className ? this.props.contentEndButton?.className : "btn-secondary");
    const label = this.props.contentEndButton?.label
      ? this.props.contentEndButton?.label
      : LanguageProvider.t(TranslationMapper.global.next);
    const icon =
      this.props.contentEndButton?.icon || this.props.contentEndButton?.icon === false
        ? this.props.contentEndButton?.icon
        : IconGt;
    const iconPosition = this.props.contentEndButton?.iconPosition
      ? this.props.contentEndButton?.iconPosition
      : IconPositionTypes.End;

    const overwrites = { className, label, icon, iconPosition };
    const _props = { ...this.props.contentEndButton, ...overwrites };

    return <Button {..._props}></Button>;
  }

  public render(): JSX.Element {
    return (
      <>
        <footer>
          <hr />
          <div className={`d-flex justify-content-lg-between ${this.props.className}`}>
            {this.contentStart()}
            {this.contentEnd()}
          </div>
        </footer>
      </>
    );
  }
}
