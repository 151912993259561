import TelemetryService from "services/telemetryService";

import * as msal from "@azure/msal-browser";

import MsalFactory from "../factories/msalFactory";

export class MsalAuthenticator {
  protected async acquireTokenAsync(scopes: string[]): Promise<string | null> {
    const account = this.getLoggedInAccount();
    if (account == null) {
      return null;
    }

    try {
      const silentRequest = {
        account: account,
        scopes: scopes,
        forceRefresh: false,
      };
      const result = await MsalFactory.getMsalContext().acquireTokenSilent(silentRequest);
      if (!result.accessToken) {
        throw Error();
      }

      return result.accessToken;
    } catch (e) {
      const redirectRequest = {
        account: account,
        scopes: scopes,
        forceRefresh: false,
      };

      MsalFactory.getMsalContext().acquireTokenRedirect(redirectRequest);
      TelemetryService.AppInsights?.trackException({
        exception: {
          name: "MsalAuthenticator.acquireTokenAsync",
          message: `Error: Could not acquire token, error: ${e}`,
        },
      });

      return null;
    }
  }

  protected getLoggedInAccount(): msal.AccountInfo | null {
    /**
     * See here for more info on account retrieval:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
     */
    const allAccounts = MsalFactory.getMsalContext().getAllAccounts();
    if (allAccounts.length === 1) {
      return allAccounts[0];
    } else if (allAccounts.length > 1) {
      MsalFactory.logoutAllAccounts(null);
    }

    return null;
  }
}
