import { ActionTypes, ActionTypesCollection } from "store/actionTypes";
import {
  IFeatureFlagStoreState,
  initialFunctionalFlageStoreState,
} from "store/state/featureFlagStoreState";

export default function featureFlagReducer(
  state: IFeatureFlagStoreState = initialFunctionalFlageStoreState,
  action: ActionTypesCollection
): IFeatureFlagStoreState {
  switch (action.type) {
    case ActionTypes.FETCHED_FEATUREFLAGS: {
      const updatedFeatureFlags = [...state.featureFlags, ...action.payload];
      return { ...state, featureFlags: updatedFeatureFlags };
    }

    default:
      return state;
  }
}
