import DateUtils from "utils/dateUtils";
import { checkFlagsExpiration, getFeatureFlagsAsync, updateFlagsWithStorageValue } from "./actions/featureFlagActions";
import { setRegistrationInitialValuesWithStorage } from "./actions/registrationActions";
import { setUnitPriceInitialValuesWithStorage } from "./actions/unitPriceActions";
import { AppAction } from "./appAction";

export function fetchInitialConfiguration(): any {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return (dispatch) => {
    dispatch(ensureFlagsUpToDate());
    dispatch(getFeatureFlagsAsync(false));
    dispatch(setRegistrationInitialValuesWithStorage());
    dispatch(setUnitPriceInitialValuesWithStorage());
  };
}

function ensureFlagsUpToDate(): AppAction {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const result = (dispatch) => {
    // Check for storage updates and update flags on change
    window.addEventListener("storage", (e: StorageEvent) => dispatch(updateFlagsWithStorageValue(e)));

    // Check validity of flags on time interval. SetInterval isn't possible on dispatches
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    (function _r() {
      setTimeout(() => {
        dispatch(checkFlagsExpiration());
        _r();
      }, DateUtils.convertMinutesToMs(1));
    })();
  };
  return result;
}
