import FlagClient from "clients/flagClient";
import IFeatureFlag from "../interfaces/IFeatureFlag";

export default class FlagService {
  private readonly flagClient: FlagClient = new FlagClient();

  public async getFlagsByNameAsync(flagNames: string[]): Promise<IFeatureFlag[]> {
    return this.flagClient.getFlagsByNameAsync(flagNames);
  }
}
