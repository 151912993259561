import IconCheck from "assets/icon-check.svg";
import Button from "components/material/button/button";
import ModalZizo from "components/modal/modal";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { connect } from "react-redux";
import { contactZizo, setUpdateModalInfoShown } from "store/actions/asideActions";
import { RootState } from "store/reducers/rootReducer";
import InputValidator from "utils/inputValidator";
import { RecaptchaUtil } from "utils/recaptchaUtil";
import { transformStringToHyperlink } from "utils/stringUtils";
import IInfoProps, { IInfoDispatchProps, IInfoStateProps } from "./interfaces/IInfoProps";
import IInfoState, { IInfoRequestProps } from "./interfaces/IInfoState";

class Info extends Component<IInfoProps, IInfoState> {
  public constructor(props: IInfoProps) {
    super(props);

    this.state = this.defaultState();

    this.onEmailAddressChange = this.onEmailAddressChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
  }

  private defaultState(): IInfoState {
    const state: IInfoState = {
      captchaToken: "",
      emailAddress: "",
      isFormValidated: false,
      isSuccessShown: false,
      message: "",
    };

    return state;
  }

  private get isFormValidated(): boolean {
    return this.state.isFormValidated;
  }

  private get data(): IInfoRequestProps {
    return {
      captchaToken: this.state.captchaToken,
      emailAddress: this.state.emailAddress,
      message: this.state.message,
    };
  }

  private handleInfoHide(): void {
    this.setState(this.defaultState());
    this.props.updateIsModalInfoShown(false);
  }

  private onEmailAddressChange(event: ChangeEvent<HTMLInputElement>): void {
    // Custom email check
    const isEmailValid = InputValidator.isEmailAddressValid(event.target.value);
    event.target.setCustomValidity(isEmailValid ? "" : "error");

    this.setState({
      emailAddress: event.target.value,
    });
  }

  private onMessageChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({
      message: event.target.value,
    });
  }

  private validateRecaptchaProps(): void {
    if (this.props.googleReCaptchaProps?.executeRecaptcha != null) {
      return;
    }

    if (RecaptchaUtil.recaptchaValidationRetryCount < RecaptchaUtil.maxRecaptchaValidationRetryCount) {
      // Sometimes the recaptcha is still loading in the DOM, we retry to compensate for this.
      // This process can't be completely handled by util, because props won't update in timeout
      window.setTimeout(this.createInfoAsync.bind(this), 2000);
      RecaptchaUtil.increaseRetryCount();
      return;
    }

    RecaptchaUtil.throwRecaptchaPropsError("Success.completeRegistration");
    RecaptchaUtil.resetRetryCounter();
  }

  private async createInfoAsync(): Promise<void> {
    if (!this.props.googleReCaptchaProps?.executeRecaptcha) {
      this.validateRecaptchaProps();
      return;
    }

    const captchaToken = await this.props.googleReCaptchaProps.executeRecaptcha();

    this.setState({ captchaToken }, () => {
      this.props.contactZizo(this.data, () => {
        this.setState({ isSuccessShown: true });

        setTimeout(() => {
          this.setState({ isSuccessShown: false, isFormValidated: false });
          this.props.updateIsModalInfoShown(false);
        }, 5000);
      });
    });
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private handleSubmit(e: any): void {
    const form = e.target;

    e.preventDefault();
    e.stopPropagation();

    this.setState({ isFormValidated: true });

    if (form.checkValidity()) {
      this.createInfoAsync();
    }
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  public modalFooter(): JSX.Element {
    const _label = this.props.isInfoLoading ? undefined : LanguageProvider.t(TranslationMapper.info.form.button.label);
    return (
      <Button
        className="btn-primary w-100"
        label={_label}
        type="submit"
        form="form-info"
        isLoading={this.props.isInfoLoading}
      ></Button>
    );
  }

  public renderForm(): JSX.Element {
    const textWithUrl = transformStringToHyperlink(
      LanguageProvider.t(TranslationMapper.info.form.text.label),
      LanguageProvider.t(TranslationMapper.info.form.text.link_label),
      `tel:${LanguageProvider.t(TranslationMapper.info.form.text.link_label).replaceAll("-", "")}`,
      "link-primary"
    );

    return (
      <>
        <div className="modal__content">
          <h2>{LanguageProvider.t(TranslationMapper.info.form.title)}</h2>
          <p>{textWithUrl}</p>
        </div>
        <Form
          noValidate
          onSubmit={(e): void => this.handleSubmit(e)}
          className={`needs-validation${this.isFormValidated ? " was-validated" : ""}`}
          id="form-info"
        >
          <Form.Group className="mb-3">
            <Form.Control
              type="email"
              placeholder={`${LanguageProvider.t(TranslationMapper.info.form.email)}*`}
              required
              onChange={this.onEmailAddressChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={`${LanguageProvider.t(TranslationMapper.info.form.message.label)}*`}
              required
              onChange={this.onMessageChange}
              minLength={5}
              maxLength={2000}
            />
            <Form.Text muted>{LanguageProvider.t(TranslationMapper.info.form.message.text)}</Form.Text>
          </Form.Group>
        </Form>
      </>
    );
  }

  public renderSuccess(): JSX.Element {
    return (
      <div className="modal__content">
        <h2 className="d-flex justify-content-center align-items-center">
          {LanguageProvider.t(TranslationMapper.info.success.title)}
          <img src={IconCheck} className="icon icon__md ms-3" alt="" />
        </h2>
        <p>{LanguageProvider.t(TranslationMapper.info.success.text)}</p>
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <ModalZizo
        handleClose={(): void => this.handleInfoHide()}
        show={this.props.isModalInfoShown}
        modalFooter={!this.state.isSuccessShown && this.modalFooter()}
      >
        {!this.state.isSuccessShown && this.renderForm()}
        {this.state.isSuccessShown && this.renderSuccess()}
      </ModalZizo>
    );
  }
}

const mapStateToProps = (state: RootState): IInfoStateProps => ({
  isModalInfoShown: state.asideState.isModalInfoShown,
  isInfoLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.InfoSending),
});

const mapDispatchToProps: IInfoDispatchProps = {
  updateIsModalInfoShown: setUpdateModalInfoShown,
  contactZizo: contactZizo,
};

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(Info));
