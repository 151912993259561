import { ActionTypes, ActionTypesCollection } from "store/actionTypes";
import { IAsideStoreState, initialAsideState } from "store/state/asideState";

export default function asideReducer(
  state: IAsideStoreState = initialAsideState,
  action: ActionTypesCollection
): IAsideStoreState {
  switch (action.type) {
    case ActionTypes.UPDATED_MODAL_INFO_SHOWN:
      return { ...state, isModalInfoShown: action.payload };
    case ActionTypes.UPDATED_MODAL_SHARE_SHOWN:
      return { ...state, isModalShareShown: action.payload };
    default:
      return state;
  }
}
