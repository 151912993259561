import "./i18n/config";

import { ApplicationConfig, AuthenticationConfig, zizoPolicies } from "config";
import MsalFactory from "factories/msalFactory";
import FeatureFlagProvider from "providers/featureFlagProvider";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { NotificationContainer } from "react-notifications";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ScriptUtil } from "utils/scriptUtil";

import { AuthenticationResult, EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { fetchInitialConfiguration } from "store/fetchInitialConfiguration";
import App from "./app";
import ErrorBoundary from "./error/components/errorBoundary/errorBoundary";
import TelemetryProvider from "./providers/telemetryProvider";
import StoreFactory from "./store/store";

MsalFactory.initialize();
ScriptUtil.addClarityScript();
ScriptUtil.addGoogleAnalyticsScript();
ScriptUtil.addGoogleTagManager();
ScriptUtil.dataLayerInit();

const store = StoreFactory.Create();
const msalInstance = new PublicClientApplication(AuthenticationConfig(zizoPolicies.policyNames.signUp));

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

store.subscribe(handleStoreChangeSubscription);
store.dispatch(fetchInitialConfiguration());

function handleStoreChangeSubscription(): void {
  FeatureFlagProvider.manageFlagChanges(store.getState().featureFlagState.featureFlags);
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ErrorBoundary>
        <MsalProvider instance={msalInstance}>
          <GoogleReCaptchaProvider reCaptchaKey={ApplicationConfig.captchaClientKey}>
            <TelemetryProvider>
              <NotificationContainer />
              <App />
            </TelemetryProvider>
          </GoogleReCaptchaProvider>
        </MsalProvider>
      </ErrorBoundary>
    </Router>
  </Provider>,
  document.getElementById("root")
);
